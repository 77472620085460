.blog-child-container {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.blog-child-container span {
    cursor: pointer;
}

.blog-child-container hr {
    margin: 0;
    margin-bottom: 15px;
}

.blog-child-content {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
}

.blog-child-content a {
    font-size: 12px;
}


@media only screen and (min-width: 320px) and (max-width: 468px) {
    .keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide{
        min-width: 330px !important;
    }
    .container-fluid, .container-lg, .container-md, .container-sm, .container-xl{
        padding-right: 0px !important;
    }
}