.casetablecolumn{
  position: sticky;
  top: 30%;
  display: flex;
  justify-content: center;
}
.caseheading1 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.8px;
}
.casestudypara1 {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 28.8px;
}
.casecustomercard1 {
  border-left: 5px solid #007cf1;
  padding-left: 20px;
  background-color: #f5f5f5;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-right: 20px;
  margin-left: 2%;
  height: 135px;
}
.casecustomerheading1 {
  color: #000;
  font-weight: 900;
  font-size: 18px;
}
.casecustomersidecards {
  align-items: center;
  justify-content: space-around;
}
.casecustomerpara1 {
  color: #2b2b2b;
  font-size: 16px;
}
.casepercentclass {
  background-color: #f8f8f8;
}
.casefinalcardhead {
  font-weight: 900;
  color: #146dff;
  font-size: 20px;
}
.casefinalpara {
  font-weight: 800;
  color: #000;
  font-size: 18px;
}
.caseiconsclass {
  background-color: #0367c5;
  border-radius: 30px;
}
.caseheading2 {
  color: white;
  font-weight: 900;
}
.casefinalcardicon {
  width: 90px;
  height: 80px;
}
.casefinalcardpara {
  color: white;
  font-size: 16px;
  padding-top: 10px;
  font-weight: 800;
}
.caseiconsdiv {
  align-items: center;
  justify-content: space-around;
}
.finalcaseimage {
  width: 100%;
}
.casecompanyclass {
  border: 2px solid #0367c5;
  margin-bottom: 10px;
  border-radius: 20px;
  padding: 10px;
}
.caseheading3 {
  font-size: 18px;
  font-weight: 900;
  color: #146dff;
}
.casecompanyimage {
  width: 100px;
  height: 80px;
}
.casecompanyawsimage {
  width: 120px;
  height: 80px;
}
.casecompanyimages {
  align-items: center;
  justify-content: center;
  margin-left: 25px;
}

@media only screen  and (min-width:320px) and (max-width:468px){
  .caseheading1{
    font-size: 20px;
    font-weight: 600;
    line-height: 8.8px;
    padding-top: 0px !important;
  }
  .casestudypara1{
    font-size: 12px;
    line-height: 23.8px;
  }
  .casecustomercard1{
    /* border-left: 5px; */
    background-color: #f5f5f5;
    padding-top: 20px;
    padding-bottom: 10px;
    margin-left: 2%;
    height: 124px;
    padding-left:0px;
    padding-left: 0px;
  }
  .casecustomercardheaing1{
    font-size: 16px!important;
    padding-left: 5px;
  }
.casecustomerpara1{
  font-size: 11px;
  padding-left: 5px;
}
.casetablecolumn{
  position: relative;
  margin-top: 30px;
}
.casestudypara2{
  font-size: 13px;
}
.casefinalcardicon{
  width: 50px;
  height: 45px;
  padding-right: 8px;
}
.casefinalcardpara{
  font-size: 10px;
  padding-right: 6px;
}


}
