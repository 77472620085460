.contact-US-New {
    /* Heading 1 → People & Careers */


    height: 68px;

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 67px;
    /* identical to box height, or 187% */
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    color: #FFFFFF;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.background-contact-Color {
    background-color: #158AFF;
    height: 405px;
}

.contact-new-paragraph {
    /* At DataObserve, our aim is to unite technology innovators and creative problem solvers. Consider joining our continuously expanding team for a fulfilling and enduring career. */


    height: 81px;

    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* or 160% */
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;

    color: rgba(255, 255, 255, 0.7);


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    padding-left: 0px;

}

.contact-text-paragraph {

    padding-top: 121px;
    padding-bottom: 121px;
}

.women-landingpage-Contact {
    max-width: 83%;
    height: 500px;

}

.women-section-height {
    background-color: #EDEDED;
}

.HEIGHT-TEXTAREA {
    height: 100px !important;
}

.Fill-Details {
    /* Section → Heading 1 → Contact Us */

    height: 42px;

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 42px;
    /* or 234% */
    /* display: flex; */
    align-items: center;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    color: #2B2B2B;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.form-control {
    /* / Section → Section → Form → Input / */



    /* width: 496px; */
    height: 48px;

    background: rgba(255, 255, 255, 1);
    ;
    border: 1px rgba(175, 175, 175, 1);

    box-shadow: inset 0px 0px 2px 1px rgba(106, 106, 106, 0.5);
    border-radius: 8px;
    outline: none !important;






}

.form-control:focus {
    border-color: none !important;
    /* box-shadow: inset 1px 1px rgba(106, 106, 106, 0.5); */





}

.background-form-Color {
    background-color: #F0F0F0;
    padding: 50px;

}

.contact-Name {
    /* Section → Section → Form → Label → Name */


    height: 21.6px;

    /* font-family: 'Roboto'; */
    font-style: normal;
    font-weight: 400 !important;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height, or 120% */

    align-items: center;

    color: #6A6A6A;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.rc-anchor-light.rc-anchor-normal {
    border: 1px #AFAFAF !important;
    background: #FFFFFF !important;


}

.contact-send-message {
    background: #158AFF;
    /* Send my message */


    width: 190px !important;
    height: 46px;
    /* left: calc(50% - 149px/2 + 0.12px);
    top: calc(50% - 21px/2 - 0.7px); */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height, or 115% */
    /* display: flex; */
    align-items: center;
    text-align: center;

    color: #FFFFFF;
    border-radius: none !important;
    outline: none !important;
    border: none !important;
    /* padding: 5px 16px; */
    margin-top: 22px;
    cursor: pointer;


}

.contact-Position {
    position: relative;
}

.contact-position-two {
    position: absolute;
    top: 15%;
    left: 50%;

}


.India-locations {
    width: 100%;
}

.india-bg-color {
    background: #E5E5E5;
    height: 178px;
    width: 100%;
}

.india-bg-color-1 {
    background: #E5E5E5;
    height: 208px;
    width: 100%;
}

.India-Hyderabad {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
}

.India-Contact {
    /* India */


    height: 26px;

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    /* identical to box height, or 122% */

    align-items: center;
    letter-spacing: -0.32px;

    color: #158AFF;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.hyderabad-contact {
    /* Hyderabad */


    height: 23px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height, or 130% */

    align-items: center;
    letter-spacing: 0.2px;

    color: #292829;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.kothaguda-contact {
    /* Kothaguda, North Hyderabad, Hyderabad, Ranga Reddy District, Telangana, India. 500084 */


    height: 47px;

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    /* or 155% */

    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;

    color: rgba(41, 40, 41, 0.8);


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.OUR-LOCATIONS-CONTACT {
    /* Heading 2 → Our Locations */

    margin-top: 50px;
    margin-bottom: 50px;
    height: 22px;

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */


    letter-spacing: -0.32px;
    text-transform: uppercase;

    color: #2B2B2B;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.Gap-our-location {
    margin-bottom: 100px;
}

.upcoming-Locations {
    /* Heading 2 → Our Locations */


    height: 22px;

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */

    align-items: center;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    color: #2B2B2B;


    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 29px;

}

.country-images-gap {
    margin-left: 20px;
}

.padding-Locations {
    margin-bottom: 52px;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none;
}