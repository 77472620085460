.finance-craft-embark {
  /* Frame 2147223720 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  width: 506px;
  height: 108px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  /* Crafting actionable insights and yielding concrete outcomes within the financial services sector. */

  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 200%;
  /* or 36px */
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;

  color: #2b2b2b;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.higher-finance {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 120%;
  /* or 43px */
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;
  text-transform: uppercase;

  color: #2b2b2b;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.ocss-5 {
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 170px;
  letter-spacing: -0.3199999928474426px;

  color: #ececec;
}



.text-Foremost-travel {
  max-width: 74% !important;
  margin-top: 9px;
}


.pro-data-travel-two {
  display: flex;
  justify-content: center;
  margin-right: 6%;


}

.pro-data-travel-three {
  display: flex;
  justify-content: center;
  margin-right: 7%;


}


.new-text-travel::after {
  content: "";
  border-right: 2px solid #505050;
  height: 7rem;
  margin-right: 12px;
  display: block;

  margin-top: 9px;


}

.new-text-travel-two::after {
  content: "";
  border-right: 2px solid #505050;
  height: 7rem;
  margin-right: 12px;
  display: block;

  margin-top: 9px;


}

.sideBar-Scroll-MENU-travel {
  list-style-type: none !important;
  margin-left: -15px;
}