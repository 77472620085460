.main {
    width: 100%;
}

.how-we {
    width: 100%;
    height: 78%;
    margin-top: 25px;
}

.data-science {
    width: 100%;
    height: 87%;
    padding-top: 64px;
    margin-left: -10px;
    margin-top: 37px;
}

.global-data-customer-background {
    background-color: #F8F8F8;
    padding: 38px;
}




.company-play-bg-video {

    position: relative;
}

.COMPANY-VIdeo {
    width: 100%;
    object-fit: cover;
    fill: linear-gradient(179deg, #000 17.86%, rgba(0, 0, 0, 0.01) 56.86%, rgba(0, 0, 0, 0.00) 107.92%);
    /* opacity: 0.6; */

}

.body-science {
    position: absolute;
    top: 125px;
    left: 133px;
}

.home-company-ourstory {
    margin-top: 10px;
    margin-bottom: 41px;
}

.color-black {
    color: black;
}

.home-color {
    color: #FE5000;
}

.journey {
    color: white;
    /* padding-top: 244px; */
    /* padding-left: 100px; */
    font-size: 40px;
    font-family: Poppins;
    font-weight: 500;

}

.our-story {
    font-size: 28.70px;
    font-family: Poppins;
    font-weight: 400;

}

.marathon-overall-top {
    margin-top: -47px;
}


.marathon-1 {
    font-family: Poppins;
    font-weight: 400;
    padding-top: 98px;
    text-align: justify;
    font-size: 18px;
    color: #707070;

}

.marathon-2 {
    font-family: Poppins;
    font-weight: 400;
    text-align: justify;
    font-size: 18px;
    color: #707070;


}

.one-number {
    height: 64px;
    width: 89px;
    padding-right: 10px;
}

.cultivate {
    margin-left: 29px;
    text-align: justify;
    border-bottom: 1px solid #e7e4e4;
    padding-bottom: 10px;
    font-size: 17px;
    color: #404041;
    word-spacing: 0px;

}

.how-we-do-this-Main {
    margin-top: 50px;
}

.cult-page {
    margin-bottom: 80px;
    background-color: #F8F8F8;
    width: 100%;
    padding-top: 43px;
}

.how-we-text {
    color: black;
    font-weight: 400;
    padding-bottom: 18px;
    font-size: 32px;
}


.target {
    height: 59px;
    width: 60px;
    margin-left: -2px;
}

.ourpurpose-body {
    margin-bottom: 84px;
}

.our-card {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    border-bottom: 3px solid #0046E8 !important;

    height: 100%;
    border-radius: 2px !important;
    /* margin: 0px 60px 0px 60px; */

}

/* .our-card-border.our-card{
        border-radius: none!important;
    } */

.card-subtitle {
    margin-top: 24px;
}

.our-purpose-1 {
    color: black !important;
    font-size: 25px;
    padding-bottom: 10px;
    margin-top: 10px;
}

.empower-para {
    color: #707070 !important;
    font-size: 13px;
}

.data-science-image {
    width: 54px;
}

.data-science-paragraph {
    padding-left: 24px;
    color: #707070;
    font-size: 15px;
}

.global-data-company {
    padding-left: 24px;
    font-size: 18px;
    font-weight: 600;

}

.global-left-customers {
    padding-left: 18px;
}

.everyperson-data {
    margin-bottom: 65px;
}

.Navigate-Journey {
    margin-left: -13px;
}



@media only screen and (min-width: 320px) and (max-width: 468px) {
    .cult-page {
        margin-bottom: 40px;
    }

    .journey {
        padding-top: 0px;
        padding-left: 0px;
        font-size: 14px;
    }

    .ourstory-heading-Main {
        font-size: 19px !important;
    }

    .about-Company {
        font-size: 10px !important;
    }

    .body-science {
        top: 30px;
        font-size: 14px;
        left: 20px;

    }

    .our-story {
        font-size: 20px;
    }

    .marathon-1 {
        padding-top: 0px;
        font-size: 14px;
    }

    .marathon-2 {
        font-size: 14px;
    }

    .how-we-text {
        font-size: 20px;
    }

    .one-number {
        height: 32px;
        width: 39px;
    }

    .cultivate {
        font-size: 12px;
        text-align: left;
    }

    .data-science {
        margin-left: 0px;
    }

    .our-card {
        width: 100% !important;
    }

    .our-purpose-1 {
        font-size: 20px;
    }

    .our-card-boder {
        margin-bottom: 10px;
    }

    .data-science-image {
        width: 45px;
    }

    .global-data-company {
        font-size: 15px;
    }

    .data-science-paragraph {
        font-size: 11px;
    }

    .ourpurpose-body {
        margin-bottom: 40px;
    }

    .everyperson-data {
        margin-bottom: 30px;
    }


}



@media only screen and (min-width: 469px) and (max-width: 768px) {
    .cult-page {
        margin-bottom: 40px;
    }

    .journey {
        padding-top: 0px;
        padding-left: 0px;
        font-size: 14px;
    }

    .ourstory-heading-Main {
        font-size: 25px !important;
    }

    .about-Company {
        font-size: 15px !important;
    }

    .body-science {
        top: 30px;
        font-size: 14px;
        left: 20px;

    }

    .our-story {
        font-size: 23px;
    }

    .marathon-1 {
        padding-top: 0px;
        font-size: 14px;
    }

    .marathon-2 {
        font-size: 14px;
    }

    .how-we-text {
        font-size: 20px;
    }

    .one-number {
        height: 40px;
        width: 53px;
    }

    .cultivate {
        font-size: 13px;
        text-align: left;
    }

    .data-science {
        margin-left: 0px;
    }

    .our-card {
        width: 100% !important;
    }

    .our-purpose-1 {
        font-size: 20px;
    }

    .our-card-boder {
        margin-bottom: 10px;
    }

    .data-science-image {
        width: 45px;
    }

    .global-data-company {
        font-size: 18px;
    }

    .data-science-paragraph {
        font-size: 13px;
    }

    .ourpurpose-body {
        margin-bottom: 40px;
    }

    .everyperson-data {
        margin-bottom: 30px;
    }

    .Navigate-Journey {
        font-size: 20px !important;
    }

    .how-we {
        height: 69%;
        margin-top: 50px;
    }

    .About-Purpose {
        padding: 21px !important;
    }

}



@media only screen and (min-width: 769px) and (max-width: 991px) {
    .cult-page {
        margin-bottom: 40px;
    }

    .journey {
        padding-top: 0px;
        padding-left: 0px;
        font-size: 14px;
    }

    .ourstory-heading-Main {
        font-size: 30px !important;
    }

    .about-Company {
        font-size: 19px !important;
    }

    .body-science {
        top: 30px;
        font-size: 14px;
        left: 53px;

    }

    .our-story {
        font-size: 26px;
    }

    .marathon-1 {
        padding-top: 0px;
        font-size: 14px;
    }

    .marathon-2 {
        font-size: 14px;
    }

    .how-we-text {
        font-size: 20px;
    }

    .one-number {
        height: 47px;
        width: 73px;
    }

    .cultivate {
        font-size: 13px;
        text-align: left;
    }

    .data-science {
        margin-left: 0px;
        /* margin-top: 0px; */
        height: 67%;
        padding-top: 0px;
        margin-top: 41px;
    }

    .our-card {
        width: 100% !important;
    }

    .our-purpose-1 {
        font-size: 20px;
    }

    .our-card-boder {
        margin-bottom: 10px;
    }

    .data-science-image {
        width: 45px;
    }

    .global-data-company {
        font-size: 18px;
    }

    .data-science-paragraph {
        font-size: 13px;
    }

    .ourpurpose-body {
        margin-bottom: 40px;
    }

    .everyperson-data {
        margin-bottom: 30px;
    }

    .Navigate-Journey {
        font-size: 31px !important;
    }

    .how-we {
        height: 58%;
        margin-top: 22%;
    }

    .About-Purpose {
        padding: 21px !important;
    }

    .marathon-overall-top {
        margin-top: auto;
        padding-top: 3%;
    }



}