
.footer-do-Logo{
  width: 150px;
  margin: 25px 20px;
  height: 22px;
}

.Footer-down-main{
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  padding-bottom: 40px;
}

.footer-heading{
  color: white !important;
}
.copy-footer-2023{
  color: rgba(255, 255, 255, 0.70);
  font-family: Roboto Condensed;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.5px; /* 150% */
  letter-spacing: -0.32px;
  text-align: center;
  padding-top: 40px;
}
      .footer {
        margin-top: -4px;
        width: 100%;
        position: relative;
        height: auto;
        background: #2B2B2B !important;
        padding-bottom: 40px;
      }
      .footer .col {
        width: 223px!important;
        height: auto;
        float: left;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        padding: 0px 20px 20px 20px;
      }
      .footer .col h1 {
        margin: 0;
        padding: 0;
        font-family: inherit;
        font-size: 12px;
        line-height: 17px;
        padding: 20px 0px 5px 0px;
        color: white !important;
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: normal !important;
        font-weight: 700 !important;
        /* color: orangered; */
      }
      .footer .col ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
      .footer .col ul li {
        color: #999999;
        font-size: 14px;
        font-family: inherit;
        font-weight: bold;
        padding: 5px 0px 5px 0px;
        cursor: pointer;
        transition: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
      }
      .social ul li {
        display: inline-block;
        padding-right: 5px !important;
      }
      
      .footer .col ul li:hover {
        color: #ffffff;
        transition: 0.1s;
        -webkit-transition: 0.1s;
        -moz-transition: 0.1s;
      }  
      
      

      .faq-class{
          background-color: #1a4ab9;
          height: fit-content;
          padding: 67px;
      }

      .form-contact{
        min-height:100px;
        
      }

      @media only screen and (min-width: 320px) and (max-width: 468px){
.footercardbody {
    padding: 56px!important;
    height: 220px !important;
}
.question{
font-size: 14px !important;
line-height: 19px !important;
}



      }


      
