.peop-carr-vid {
  position: relative;
}

.peop-carr-videos {
  width: 100%;
  height: 405px;
  object-fit: cover;
}

.peop-carr-text {
  position: absolute;
  top: 27%;
  left: 0%;
  right: 0%;
}

.peop-carr-h1 {
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 67px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  color: white;
}

.peop-carr-para1 {
  font-family: Merriweather;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  color: #ffffffb2;
}

/* section2 */
.sec-2-blue {
  background-color: #158aff;
  width: 100%;
  height: 329px;
  padding: 100px 0px 100px 0px;
  margin-top: -1%;
}

.sec-2-div {
  padding: 100px 0px 100px 0px;
}

.sec-2-join-h1 {
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  color: white;
  /* margin-left: 14%;
  margin-top: 6%; */
  padding-left: 0px !important;
}

.sec-2-btn {
  margin: auto;
  margin-right: 0%;
}

.view-opn-btn {
  background: #ececec;
  outline: none !important;
  height: 46px;
  border: none;
  font-family: Roboto;
  font-size: 16px;
  border: none;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #158aff;
  cursor: pointer;
  width: 194px;

  /* margin-left: 23%;
  margin-top: 9%; */
}

/*  */
/* .sec-3-why-j-o {
  height: 2174px;
  top: 469px;
} */
.w-j-do-h1 {
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: -0.3199999928474426px;
  text-align: center;
  color: #158aff;
  margin-top: 100px;

}

.nine-items-h1 {
  font-family: Roboto Condensed;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b2b2b;
  text-transform: uppercase;
}

.nine-items-para {
  font-family: Merriweather;
  font-size: 15px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #2b2b2b;
  padding: 15px 0px 10px 0px;
}

.nine-items {
  margin-top: 100px;
}

.last-ittemm {
  margin: auto;
}

/* .sec-4-open-pos {
  background-color: #ececec;
 
} */



.op-position {
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 42.12px;
  letter-spacing: -0.5px;
  color: #2b2b2b;
}

.op-para {
  font-family: Merriweather;
  font-size: 18px;
  font-weight: 400;
  line-height: 32.04px;
  color: #2b2b2b;
  padding-bottom: 18px;
}

.h1-dev {
  font-family: Roboto Condensed;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.7px;
  letter-spacing: -0.25px;
  color: #2b2b2b;
  padding-bottom: 18px;
  padding-top: 10px;
}

.dev-para {
  font-family: Merriweather;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.5px;
  color: #2b2b2b;
  margin-left: 17px;
}

.sec-4-starting {
  background-color: #ececec;
  align-items: left;
  text-align: left;
  margin-top: 77px;
  padding-top: 95px;
  padding-bottom: 95px;
}

.row-items {
  margin-right: 0px !important;
}



.last-para-tag {
  font-family: Merriweather;
  font-size: 18px;
  font-weight: 400;
  line-height: 32.04px;
  padding-top: 23px;
}

.last-btn-tag {
  outline: none !important;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: 20.7px;
  text-align: center;
  background: #158aff;
  color: white;
  height: 46px;
  width: 200px;
  border: none;
  outline: none;
}

.button-last-main {
  padding-top: 20px;
}

.last-main-black {
  background-color: #2b2b2b;
  color: white;
  height: 78px;
}

.Multi-text-gap {
  margin-bottom: 77px;
}