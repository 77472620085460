.insight-back-color {
    background: #ECECEC;

}

.down-spce {
    padding-bottom: 100px;
}

.trending-sit {
    padding: 100px 0px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    border-bottom: none !important;
    background: #158AFF;
    color: #FFFFFF;
    border-radius: 0px;
}





.trending h1 {
    /* Heading 1 → People & Careers */
    /* height: 68px; */
    text-align: center;
    padding: 45px 0px;

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 67px;
    /* identical to box height, or 187% */
    letter-spacing: -0.32px;
    text-transform: uppercase;


    color: #2B2B2B;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.insigh-text {
    /* Link → Heading 2 */
    /* position: absolute; */
    /* width: 285px; */
    height: 82px;
    /* margin-left: 40px; */
    margin-bottom: 25px;
    /* left: 50px;
top: 0px; */

    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;

    /* display: flex;
align-items: center; */

    color: #242424;
}

.insigh-text-date {
    /* Mar 15, 2024 */

    /* width: 73.58px; */
    height: 20px;
    /* margin-left: 40px; */
    margin-right: 8px;

    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 11.5781px;
    line-height: 20px;
    /* identical to box height, or 173% */
    display: flex;
    align-items: center;

    color: #6B6B6B;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.insigh-text-read {
    /* 7 min read */

    width: 60.51px;
    height: 20px;

    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 11.4766px;
    line-height: 20px;
    /* identical to box height, or 174% */
    display: flex;
    align-items: center;

    color: #6B6B6B;


    /* Inside auto layout */
    flex: none;
    order: 2;
    flex-grow: 0;

}

.insigh-dash {
    /* · */

    /* width: 3.22px; */
    height: 20px;
    margin-right: 8px;
    padding-bottom: 8px;

    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    display: flex;
    align-items: center;

    color: #6B6B6B;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.Trending-logo {
    /* 01 */

    width: 46px;
    height: 38px;

    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    /* identical to box height, or 127% */
    /* leading-trim and text-edge are draft CSS properties.

Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
*/

    display: flex;
    align-items: center;
    letter-spacing: -0.448px;

    color: #BDBDBD;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.blogs-Trending-logo {
    /* Section → Link → 1*JeaJWN5uIwe4UqSESIP4Nw.jpeg */

    width: 200px;
    height: 134px;

    /* background: url(.jpg); */

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.Marking-insigh-text {

    /* Marking the Web’s 35th Birthday: An Open Letter */

    /* position: absolute; */
    width: 447.75px;
    /* height: 24px; */
    left: 0px;
    top: 0px;

    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    font-size: 18.125px;
    line-height: 24px;
    /* identical to box height, or 132% */
    display: flex;
    align-items: center;

    color: #242424;



}

.insigh-text-Marking {

    /* Sir Tim Berners-Lee’s open letter to mark the occasion of the Web’s 35th Birthday. */

    /* position: absolute; */
    width: 472.68px;
    height: 18px;
    left: 0px;
    top: -0.5px;

    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 14.125px;
    line-height: 20px;
    /* or 142% */
    display: flex;
    align-items: center;

    color: #6B6B6B;
}

.ending-width {
    max-width: 100% !important;
    padding-left: 0px;
}

.insigh-text-date-mar {
    margin-right: 15px;
    height: 20px;
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 11.5781px;
    line-height: 20px;
    /* identical to box height, or 173% */
    display: flex;
    align-items: center;

    color: #6B6B6B;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.insigh-text-read-min {
    /* Section → 5 min read */



    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 11.5781px;
    line-height: 20px;
    /* identical to box height, or 173% */
    display: flex;
    align-items: center;

    color: #6B6B6B;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.btn-dark-domain {
    /* Frame 2147224064 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 8px;
    gap: 10px;
    margin-bottom: 10px;

    /* width: 62px; */
    height: 28px;
    outline: none !important;
    background: #F2F2F2;
    border: 1px solid #DADADA;
    border-radius: 222px;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;


    /* Section → 5 min read */

    /* width: 46px;
height: 20px; */

    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 11.5781px;
    line-height: 20px;
    /* identical to box height, or 173% */
    display: flex;
    align-items: center;

    color: #158AFF;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.trending-list-1 {
    display: flex;
    margin-top: 48px;
}

.trending-list {
    display: flex;
    margin-top: 24px;
}

.Save-logo {
    /* Section → Link → SVG - Add to list bookmark button */

    width: 25px;
    height: 25px;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.consider-save {
    /* justify-content: space-around; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 18px;
    gap: 10px;
    /* margin: 0 auto; */
    /* width: 112px; */
    height: 45px;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.Marking-ending {
    display: flex;
    justify-content: space-between;
}

.Discover-Marking {
    /* Heading 2 → Discover more of what matters to you */

    /* position: absolute; */
    /* width: 264.73px; */
    height: 20px;
    left: 0px;
    top: 0px;
    margin-top: 48px;

    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 14.25px;
    line-height: 20px;
    /* identical to box height, or 140% */
    display: flex;
    align-items: center;

    color: #242424;


}

.matter-one {
    margin-left: 35px;
    /* max-width: 35.333333%; */


}

.Marking-insight {
    padding-left: 0px !important;
}

.more-buttons {
    /* Link */
    outline: none !important;

    margin-left: 6px;


    height: 38px;

    font-size: 13px;
    width: 120px;

    background: #F2F2F2;
    border: 1px solid #F2F2F2;
    border-radius: 100px;
    font-family: Merriweather;
    font-weight: 400;

}


.more-buttons-1 {
    /* Link */
    outline: none !important;

    margin-left: 6px;


    height: 38px;

    font-size: 13px;
    width: 115px;

    background: #F2F2F2;
    border: 1px solid #F2F2F2;
    border-radius: 100px;
    font-family: Merriweather;
    font-weight: 400;

}

.more-buttons-2 {
    /* Link */
    outline: none !important;


    margin-left: 6px;
    height: 38px;

    font-size: 13px;
    width: 106px;

    background: #F2F2F2;
    border: 1px solid #F2F2F2;
    border-radius: 100px;
    font-family: Merriweather;
    font-weight: 400;

}

.more-buttons-3 {
    /* Link */
    outline: none !important;


    margin-left: 6px;
    height: 38px;

    font-size: 13px;
    width: 145px;
    /* padding: 9px 17px; */

    background: #F2F2F2;
    border: 1px solid #F2F2F2;
    border-radius: 100px;
    font-family: Merriweather;
    font-weight: 400;


}

.more-buttons-4 {
    /* Link */
    outline: none !important;

    margin-left: 6px;

    height: 38px;

    font-size: 13px;
    width: 79px;
    /* padding: 9px 17px; */

    background: #F2F2F2;
    border: 1px solid #F2F2F2;
    border-radius: 100px;
    font-family: Merriweather;
    font-weight: 400;

}

.more-buttons-5 {
    /* Link */
    outline: none !important;

    margin-left: 6px;

    height: 38px;

    font-size: 13px;
    width: 118px;
    /* padding: 9px 17px; */

    background: #F2F2F2;
    border: 1px solid #F2F2F2;
    border-radius: 100px;
    font-family: Merriweather;
    font-weight: 400;

}

.more-buttons-6 {
    /* Link */
    outline: none !important;


    margin-left: 6px;
    height: 38px;

    font-size: 13px;
    width: 145px;
    /* padding: 9px 17px; */

    background: #F2F2F2;
    border: 1px solid #F2F2F2;
    border-radius: 100px;
    font-family: Merriweather;
    font-weight: 400;

}

.more-buttons-7 {
    /* Link */
    outline: none !important;
    box-sizing: border-box;
    margin-left: 6px;

    height: 38px;

    font-size: 13px;
    width: 109px;
    /* padding: 9px 17px; */

    background: #F2F2F2;
    border: 1px solid #F2F2F2;
    border-radius: 100px;
    font-family: Merriweather;
    font-weight: 400;

}

.more-buttons-8 {
    /* Link */
    outline: none !important;
    box-sizing: border-box;

    margin-left: 6px;
    height: 38px;

    font-size: 13px;
    width: 79px;
    /* padding: 9px 17px; */

    background: #F2F2F2;
    border: 1px solid #F2F2F2;
    border-radius: 100px;
    font-family: Merriweather;
    font-weight: 400;

}

.space-gap {
    display: flex;
    justify-content: space-between;
}

.save-text-date {
    /* Saved */

    margin: 0 auto;
    width: 43px;
    height: 20px;

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* identical to box height, or 111% */
    display: flex;
    align-items: center;

    color: #2B2B2B;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.more-buttons-02 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    /* padding: 0px 15px; */
}

.Most-Marking {
    /* Link → See more topics */

    /* position: absolute; */
    width: 100.42px;
    height: 20px;
    /* left: 0px;
top: 194px; */

    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 12.9062px;
    line-height: 20px;
    /* identical to box height, or 155% */
    display: flex;
    align-items: center;
    margin-top: 25px;

    color: #158AFE;


}

hr {
    margin-top: -4px;
    /* margin-bottom: 1rem; */
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.nav-tabs {
    border-bottom: none !important;
}

.Blogs-Insights {
    color: #2B2B2B;
    font-weight: 400;
    font-style: Roboto Condensed;
    font-size: 18px;
    line-height: 20px;

}



.Read-More-trending {
    font-size: 13px;
    cursor: pointer;
    color: #007bff;
    text-transform: uppercase;
    font-weight: 400;

}

.Read-More-trending:hover {
    transition-delay: .05s;
    transition: all .25s ease;
    color: #158AFF;

}