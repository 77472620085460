.medical {
  width: 100%;
}

.clinical-trails {
  width: 100%;
}

.genomics {
  width: 100%;
}

.mg-right {
  margin: 0px 10px 10px 0px;
}

.ghgh {
  margin-left: 15px;
}



.platforms {
  text-align: center;
  color: #050505;
  text-align: center;
  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 55px;
  /* 125% */
  letter-spacing: -0.32px;
}

.platformspara {
  color: rgba(0, 0, 0, 0.80);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
  /* 160% */
  letter-spacing: -0.32px;
}

.cntr-text {
  justify-content: center;
}

.boxtext {
  font-size: 14px;
  text-align: inherit;
  justify-content: center;
}

.box {
  margin: 0px !important;
}

.case-studies {
  text-align: center;
}

.Built-back-colour-1 {
  height: 289px;
  /* / padding: 24px; / */
  position: relative;
  border-radius: 25px;
}

.right-media-Corner-1 {
  position: absolute;


  width: 150px;

  right: -1px;
  top: 0%;
}

.built-corner-image-1 {
  width: 100%;
  height: 1%;
  border: 2px solid #1a4ab9;
  border-top-right-radius: 25px;
}

/* .searcheye{
  width: 50px;
height: 60px;
} */
.instudrie-box{
  height: 260px !important; 
 padding: 30px 30px;
 box-shadow: 2px 2px 35px -24px #323c8d;
 border-radius: 20px;
 margin: 25px 0px;
}
.industries-play-bg-video {
  position: relative;
  /* height: 759px; */
}

.life-science-video {
  width: 100%;
  object-fit: cover;
  /* position: relative; */
}

.mainimg {
  position: absolute;
  top: 196px;
  left: 116px;
}
/* 
.indheading {
  padding-top: 195px;
  padding-left: 115px;


} */

.industies-mainhead {
  color: #FFF;
  font-family: Poppins;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 67.2px;
  letter-spacing: -0.32px;

  display: flex;
  width: 759px;
  height: 202px;
  flex-direction: column;
  justify-content: center;
}



.industries-subhead {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
  letter-spacing: -0.32px;
  margin-top: 41px;

  display: flex;
  width: 789px;
  height: 72px;
  flex-direction: column;
  justify-content: center;
}

.btn {
  display: inline-flex;
  height: 61.19px;
  padding: 20px 46px 21.19px 48px;

  align-items: center;
  gap: 9.16px;
  flex-shrink: 0;

}

.btninner {
  margin-left: 70px;
  padding-right: 24px;
  padding-left: 24px;
  padding-top: 13px;
  padding-bottom: 13px;
  color: #1A4AB9;
  font-family: Poppins;
  font-size: 17.6px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.2px;
  /* 109.091% */
  letter-spacing: -0.32px;
  border-color: white;
}

.main-exp {
  /* margin: 5%; */
  /* padding-bottom: 50px; */
  padding-top: 60px;
  /* margin-top: 7%; */
}

.arpng {
  padding-left: 8px;
  margin-top: 5px;
  display: flex;
  max-width: 23px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.expertiseind {
  text-align: center;
  color: #050505;
  text-align: center;
  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: -0.32px;

}

.exp-para {
  text-align: center;
  color: rgba(0, 0, 0, 0.80);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29.6px;
  letter-spacing: -0.32px;
}

.pharma {
  color: #1069D5;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.6px;
  letter-spacing: -0.32px;
  width: 100%;
}

.drug-list {
  list-style-type: none;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  margin-left: -34px;
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.6px;
  letter-spacing: -0.32px;
  width: 100%;
}

.list-border {
  border-bottom: 1px solid #F1F4FB;
  display: block;
  width: 100%;
}

.drugbox {
  background-color: white;
  border-radius: 8px;
}

.ind-boxhead {
  margin-left: 20px;
  font-weight: 600 !important;
}

.industriesexpclass {
  background-color: #F6F9FF;
  height: 960px;
  margin-top: -1%;
}

.pmt {
  margin-top: 50px;
}

.cs-div {
  margin-top: 36px;
}

.bles-div {
  padding-top: 8px;
  margin-top: 100px;
}

.case {
  color: #050505;
  text-align: center;
  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: -0.32px;
}

.mpara {
  color: #050505;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 52.5px;
  letter-spacing: -0.32px;

}


.guidelines {
  color: rgba(0, 0, 0, 0.70);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
  /* 160% */
  letter-spacing: -0.32px;
 
}

.ble-s {
  color: #050505;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.bles-data {
  color: rgba(0, 0, 0, 0.90);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
  letter-spacing: -0.32px;
}

.logo-images img {
  width: 35px;
}

/* .testmonials {
  color: #050505;
  text-align: center;
  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: -0.32px;
  padding-top: 15px;
}

.tst-card {
  width: 100%;
  height: 500px;
  background-image: url("../../public/images/tst-card.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  margin-top: 25px;
}

.tst-nm {
  padding-top: 8px;
  padding-left: 25px;
}

.nm {
  color: #FFF;
  font-family: Poppins;
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: 56.65px;
  
  letter-spacing: 1.01px;

}

.nm-para {
  color: rgba(255, 255, 255, 0.70);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.6px;
  
  letter-spacing: -0.32px;
}

.tst-heading {
  color: #FFF;
  font-family: Rubik Glitch;
  font-size: 55px;
  font-style: normal;
  font-weight: 400;
  line-height: 56.65px;
  
  letter-spacing: 1.01px;
  padding-top: 8px;
  padding-left: 63px;
}

.tst-para {
  color: #FFF;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 35.2px;
  
  padding-top: 30px;
  padding-left: 68px;
}

.vishnu {
  color: #FFF;
  font-family: Poppins;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.51px;
  
  letter-spacing: -0.34px;
}

.director {
  color: rgba(255, 255, 255, 0.60);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  
  letter-spacing: -0.32px;
}

.namecard {
  margin-top: 101px;
  margin-left: 70px;
} */

.logo-ul li {
  list-style-type: none;
  padding-right: 15px;
}

.logo-ul li img {
  width: 113.55px;
  height: 27.47px;
  max-width: 113.55px;
}

.tlnt-heading {
  color: #050505;
  text-align: center;
  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 55px;
  letter-spacing: -0.32px;

}

.tlnt-para {
  color: rgba(0, 0, 0, 0.70);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
  /* 160% */
  letter-spacing: -0.32px;

}

.tlnt-card {
  border-radius: 30px;
  background: linear-gradient(180deg, #E5E9F5 0%, #CDDDE8 53%, #B4BDDE 100%);
}

.tlnt-card-para {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 27.9px;
  /* 155% */
  letter-spacing: -0.32px;
}

.tlnt-docreators {
  color: #050505;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 120% */
  letter-spacing: -0.32px;
  margin-top: 5px;
}



.labimg {
  position: relative;
  margin-top: 45px;
  padding-top: 45px;
}

.lab-img-section img {
  width: 100%;
}

.labimg .lab-data-industries {
  position: absolute;
  top: 27%;
  left: 50%;  
  /* width: 511px;
  height: 367.38px;
  padding: 8px;
  margin-top: -64px; */
}

.industries-bg-pink {
  background-color: #EF7179;
  border-radius: 27px;
  height: 236px;
  width: 390px;
}

.ind-card-title {
  color: #FFF;
  /* color: #FFF; */
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.5px;
  /* 125% */
  letter-spacing: -0.32px;
}

.card-text {
  /* background: #ffffff; */
  /* opacity: 0.7; */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 250;
  line-height: 24.8px;
  /* 155% */
  letter-spacing: -0.32px;


}

.card-subtitle {
  color: #FFF;
 font-size: 11px ;
 margin-top: 5px;
 line-height: 16px;

}
.readmore{
color: white;
margin-top: 14px;
}

.doc {
  color: #FFF;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.6px;
  /* 182.857% */
  letter-spacing: 1px;
  text-transform: uppercase;
}

.benfits {
  color: #050505;
  text-align: center;
  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 55px;
  /* 125% */
  letter-spacing: -0.32px;
  margin-top: 45px;
}

.benfits-para {
  color: rgba(0, 0, 0, 0.80);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
  /* 160% */
  letter-spacing: -0.32px;
  padding-bottom: 58px;
}

.last-logos {
  list-style-type: none;
  padding-right: 15px;

}

.percentage {
  color: #050505;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38.4px;
  /* 120% */
}

.pp-data {
  color: #050505;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.8px;
  /* 155% */
  letter-spacing: -0.32px;
}

.down-logo {
  width: 67px;
  height: 85px;
  max-width: 270px;
  margin-bottom: 10px;
}

.down-logo-1 {
  width: 67px;
  height: 69px;
  max-width: 270px;
  margin-bottom: 10px;
}

.pharmalink {
  border: 0px !important;
  border-bottom: 3px solid #E2ECFF !important;
  color: rgba(5, 5, 5, 0.35);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 3px solid #1069D5 !important;
}

/* .ind-dg-head{
  color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 25.6px;
letter-spacing: -0.32px;
} */

.ind-pcmg-heading {
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.6px;
  letter-spacing: -0.32px;
}

.ind-pcmg-heading .btn {
  color: #000 !important;
  text-decoration: none;
}

.pcmg-border {
  border: none;
  background-color: #F3F6FF !important;
}

.pcmg-border-2 {
  border: none;
  background-color: #FEE !important;
}

.pcmg-border-3 {
  border: none;
  background-color: #D9F5ED !important;
}

.pcmg-border-4 {
  border: none;
  background-color: #FFFBE6 !important;
}

.ind-bb-sg-1::after {
  content: "";
  border-bottom: 2px solid #FF4925;
  width: 90%;
  display: block;
  margin: auto;
  margin-top: -27px;
}

.ind-bb-sg-2::after {
  content: "";
  border-bottom: 2px solid #1A4AB9;
  width: 90%;
  display: block;
  margin: auto;
  margin-top: -27px;
}

.ind-bb-sg-3::after {
  content: "";
  border-bottom: 2px solid #00B686;
  width: 90%;
  display: block;
  margin: auto;
  margin-top: -27px;
}


.ind-bb-sg-4::after {
  content: "";
  border-bottom: 2px solid #00B686;
  width: 90%;
  display: block;
  margin: auto;
  margin-top: -27px;
}

.ind-bb-sg-5::after {
  content: "";
  border-bottom: 2px solid #FFD703;
  width: 90%;
  display: block;
  margin: auto;
  margin-top: -27px;
}

.ind-bb-sg-6::after {
  content: "";
  border-bottom: 2px solid #FF4925;
  width: 90%;
  display: block;
  margin: auto;
  margin-top: -27px;
}

.ind-pcmgdata {
  color: rgba(0, 0, 0, 0.70);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.6px;
  letter-spacing: -0.32px;
}

/* .pcmg{
  background: #F3F6FF;
} */
.ind-dg-pr {
  color: rgba(0, 0, 0, 0.70);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
  letter-spacing: -0.32px;
}

.life {
  color: white;
  font-size: 22px;
  font-Style: normal;
  font-Weight: 600;
  text-align: left;
}

.button1 {
  display: flex;
  height: 61px;
  max-width: 255px;
  padding: 20px 46px 21px 48px;
  justify-content: center;
  align-items: center;
  border: none;
  margin-top: 50px;
  border-radius: 5px;
  /* margin-left: 13%!important; */
}

.span1 {
  color: #FF4925;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
}

.fa fa-long-arrow-right ml-3 {
  width: 23px;
  font-size: 29px;
  flex-shrink: 0;
  color: #FF4925;
}

.newaccede {
  width: 60px !important;
  height: 20px !important;
}

.slokaenergy {
  width: 100px !important;
}

.gbreco {
  width: 95px !important;
}

.newsrdsystems {
  width: 70px !important;
}

.tlnt-btn {

  border: none;
  border-radius: 4px;
  display: flex;
  padding: 10px 20px;
  align-items:center;
  justify-content: space-between;

}

.tlnt-botn {
  padding: 10px 20px;
  font-size: 15px;
  color: white;
  border-radius: 4px;
  background: #1A4AB9;
  border: none;
  height: 47px;
  /* align-items: baseline; */
  /* align-items: center; */

}

/* .card-body industries-{
                border-radius:27px;
                height: 250px;
                width: 390px;
              } */
.doodpng {
  height: 100px;
}

.card-industries {
  border: none;
  background: none;
  /* transform: translatey(-10px); */
  /* width: 390px; */
  /* padding-left:129px; */
}

.tlnt-card {
  border-radius: 30px;
  width: 100%;
  margin-left: -15px;
}

.tlnt-card-web-img {
  width: 80%;
  border-radius: 30px;
  margin-left: -15px;
}

.faq {
  margin: 0px;

}

.eng {
  margin-top: 40px;
  /* margin-left: -100px; */

}

.industries-pharama {
  width: 91%;
  height: 75%;
  padding-top: 65px;


}

.longclinicaltrials {
  width: 86%;
  height: 81%;
  padding-top: 60px;
}

.longmedicaldevices {
  width: 79%;
  height: 93%;
  padding-top: 104px;
  /* margin-left: 38px; */
}

.multiomics {
  width: 72%;
  height: 86%;
  padding-top: 86px;
}

.cardsdetails {
  color: white;
}

.card-imgs{
height: 278px;
width: 100%;
}

.card-imgs{
  margin-bottom: 46px;
  margin-left: -10px;
  border-radius: 20px;
}


.ind-card-text-3{
  color: white;
}



.lifescience-labimg{
  margin-top: 10%;
}

.lifescience-pink-wholediv{
  position: absolute;
  top: 24%;
    left: 10%;
}
.lifescience-labimg{
  position: relative;
}


.lifescience-n-pink{
  background-color: #EF7179;
    border-radius: 30px;
    padding: 30px 30px 30px 50px;
}
.lifescience-doc{
  color: #FFF;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 25.6px; /* 182.857% */
letter-spacing: 1px;
text-transform: uppercase;
}
.lifescience-card-title{
  color: #FFF;
font-family: Poppins;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: 27.5px; /* 125% */
letter-spacing: -0.32px;
}
.lifescience-subtitle{
  color: rgba(255, 255, 255, 0.80);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 24.8px; /* 155% */
letter-spacing: -0.32px;
}
.lifescience-readmore{
  color: #FFF;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 25.6px; /* 160% */
letter-spacing: -0.32px;
}

.ind-platformspara{
  text-align: center;
}
  
@media only screen and (min-width:320px) and (max-width:468px) {
  .lifescience-n-pink {
    background-color: #EF7179;
    border-radius: 30px;
    padding: 15px 15px 15px 30px;
    margin: 19px;
}
.lifescience-pink-wholediv{
  left: 0px;
}

.lifescience-doc {
  color: #FFF;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.6px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.lifescience-card-title {
  color: #FFF;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.5px;
  letter-spacing: -0.32px;
}
.lifescience-subtitle {
  color: rgba(255, 255, 255, 0.80);
  font-family: Poppins;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: -0.32px;
}
.lifescience-readmore {
  color: #FFF;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 25.6px;
  letter-spacing: -0.32px;
}

.lifescience-labimg {
  margin-top: 0%;
}
.tlnt-card{
  margin-left: 0px !important;
}









  .instudrie-box{
    height: fit-content !important
  }

  .mainimg {
    width: fit-content !important;
    position: absolute;
    top: 16px;
    left: 14px;
  }

  .Industrie-FInd{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .down-logo{
    width: 65px;
    height: 80px;
  }

  .percentage {
    font-size: 25px;
  }

  .ble-s {
    font-size: 18px;
  }


  .life {
    font-size: 13px;
    line-height: 22px;
    margin-top: 0% !important;
  }

  .industies-mainhead {
    line-height: 22px;
    font-size: 15px;
    width: 294px !important;
    font-weight: 500;
  }

  .industies-mainhead {
    color: #FFF;
    font-family: Poppins;
    font-size: 17px;
    font-style: normal;
    font-weight: 500;
    line-height: 20.2px;
    letter-spacing: -0.32px;
    display: flex;
    width: 759px;
    height: fit-content;
    flex-direction: column;
    justify-content: center;
  }

  .industries-subhead {
    font-size: 9px;
    line-height: 12.6px;
    margin-top: -37px;
    width: 294px;
  }

  .data1 {
    margin-top: -18px;
  }

  .button1 {
    display: flex;
    height: 26px !important;
    max-width: 232px !important;
    padding: 14px 22px 14px 22px !important;
    justify-content: center;
    align-items: center;
    border: none;
    margin-top: -26px !important;
    border-radius: 5px !important;
    /* margin-left: 2px !important; */
  }

  .span1 {
    color: #FF4925;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
  }

  .fa fa-long-arrow-right ml-3 {
    width: 23px;
    font-size: 29px;
    flex-shrink: 0;
    color: #FF4925;
  }

  .industriesexpclass {
    background-color: #F6F9FF;
    height: fit-content;
    padding-bottom: 10px;
  }

  .expertiseind {
    /* margin-top: -53px; */
    font-size: 25px;
  }

  .exp-para {
    text-align: justify;
    word-spacing: 0px;
    font-size: 15px;
    line-height: 23px;

  }

  .pcmdheads {
    align-items: center;
    justify-content: center;
    font-size: 15px;
  }

  .platforms {
    font-size: 17px;
    line-height: 25px;
    /* margin-top: 47px; */
  }

  .pmt {
    margin-top: 20px;
  }

  

  .ind-boxhead {
    margin-left: 20px;
    font-weight: 600 !important;
    font-size: 16px;
}

  .ind-card-title {
    font-size: 14px !important;
    line-height: 20.5px;
    text-align: left;
  }

  .ind-card-text-3 {
    font-size: 10px;
    line-height: 14.8px;
    color: white;
    text-align: left;

  }

  .card-imgs {
    margin-left: -10px;

    /* padding-top: 4px; */
    width: 113% !important;
    height: 278px !important;
    margin-top: 6px;
    
  }

  /* .Built-back-colour-1 {
    height: 556px;
  } */

  .industries-play-bg-video{
    height: 180px;
  }
  .newaccede {
    margin-left: 17px;
    width: 50px !important;
    height: 15px !important;
  }

  .slokaenergy {
    margin-left: 6px;
    width: 85px !important;
  }

  .gbreco {
    padding-left: 6px;
    width: 84px !important;
  }

  .newsrdsystems {
    width: 73px !important;
  }

  .mpara {
    width: 100%;
    text-align: center;
    font-size: 15px;
    line-height: 25.5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .guidelines {
    /* width: fit-content; */
    font-size: 13px;
    line-height: 21.6px;
    padding-left: 10px;
  }

  .tasdiv{
    margin-top: 30px !important;
  }

  .tlnt-heading {
    font-size: 24px;
    line-height: 47px;
  }

  .tlnt-para {
    text-align: justify;
    font-size: 13px;
    line-height: 25.6px;

  }

  .tlnt-card-para {
    text-align: justify;
    /* margin-left: 107px; */
    font-size: 10px;
    line-height: 19.9px;
  }

  .tlnt-docreators {
    /* margin-left: 108px; */
    font-size: 19px;
  }

  .tlnt-btn {
    border: none;
    border-radius: 4px;
    padding: 10px 0px;
  
   
  }

  .tlnt-botn {
    padding: 10px 20px;
    font-size: 10px;
    color: white;
    background: rgb(26, 74, 185);
    border: none;
    
  }
  /* .dood {
    top: 62%;
   left: 81%;
    position: absolute;
} */
  .newlab {
    height: 161px;
    width: 320px;
  }

  .lab-img {
    display: none;
    /* height: 161px;
    width: 320px; */
  }

  .industries-bg-pink {
 
    margin-top: -40px;
    height: 224px !important;
    width: 320px !important;
  }

  .labimg .lab-data-industries {
    left: 0%;
  }

  .mb-2 {
    text-align: justify;
    font-size: 8px !important;
    line-height: 14px !important;
    margin-top: 5px !important;
  }

  .readmore {
    margin-top: 9px !important;
  }

  .benfits {
    padding-top: 8rem;
    margin-top: 9rem;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
  }

  .benfits-para {
    font-size: 15px;
    line-height: 23.6px;
  }

 

  .lab-data-industries {
    width: 175px !important;
  }

 

 

  .ind-platformspara {
    line-height: 18.6px;
    
    text-align: center;
    font-size: 12px;
  }

  .text-left {
    text-align: center !important;
  }

  .boxtext {
    font-size: 14px;
  }

  /* .card {
    width: 100% !important;
    margin-top: 0px !important;
  } */

  .faq {
    font-size: 10px;
    margin: 0px;
  }

  .card-bodys {
    font-size: 8px !important;
    text-align: justify;
  }

  .cardsdetails {
    font-size: 12px;
    /* margin-left: 72px !important; */
    color: white;
    text-align: center;
  }

  .ind-card-title {
    text-wrap: wrap;
    /* margin-left: -78px!important; */
  }

  .question {
    line-height: 14.8px;
    font-size: 8px;

  }

  .industries-pharama {
    width: 91%;
    height: 75%;
    padding-top: 28px;
    margin-left: 7px !important;

  }

  .longclinicaltrials {
    margin-left: 27px;
    width: 81%;
    height: 81%;
    padding-top: 28px;
}

.longmedicaldevices {
  width: 93%;
  height: 93%;
  padding-top: 28px;
  margin-left: 16px;
}

  .multiomics {
    width: 86%;
    height: 86%;
    padding-top: 28px;
    margin-left: 17px;
  }

  .tlnt-card-web-img {
    width: 100%;
    border-radius: 0px;
    /* margin-left: -15px; */
}

.bles-div{
  margin-top: 0px;
}


.doodpng{
  height: 69px;
}

.App-logo-industrie{
  height: 46px;
  width: 45px;
}

}

@media only screen and (min-width:469px) and (max-width:768px) {
  
 
 
 
  .instudrie-box{
    height: fit-content !important
  }

  .mainimg {
    width: fit-content !important;
    position: absolute;
    top: 16px;
    left: 14px;
  }

  .Industrie-FInd{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .down-logo{
    width: 65px;
    height: 80px;
  }

  .percentage {
    font-size: 25px;
  }

  .ble-s {
    font-size: 18px;
  }


  .life {
    font-size: 15px;
    line-height: 22px;
    margin-top: 0% !important;
  }

  .industies-mainhead {
    line-height: 22px;
    font-size: 15px;
    width: 294px !important;
    font-weight: 500;
  }

  .industies-mainhead {
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 23.2px;
    letter-spacing: -0.32px;
    display: flex;
    width: 759px;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
    justify-content: center;
  } 
  .industries-subhead {
       font-size: 12px;
       line-height: 15.6px;
    margin-top: -21px;
    width: 294px;
  }

  .main-exp{
    padding-top: 0px;
  }

  .data1 {
    margin-top: -9px;
  }

  .button1 {
    display: flex;
    height: 26px !important;
    max-width: 232px !important;
    padding: 14px 22px 14px 22px !important;
    justify-content: center;
    align-items: center;
    border: none;
    margin-top: -11px !important;
    border-radius: 5px !important;
    /* margin-left: 2px !important; */
  }

  .span1 {
    color: #FF4925;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
  }

  .fa fa-long-arrow-right ml-3 {
    width: 23px;
    font-size: 29px;
    flex-shrink: 0;
    color: #FF4925;
  }

  .industriesexpclass {
    background-color: #F6F9FF;
    height: fit-content;
    padding-bottom: 10px;
  }

  .expertiseind {
    /* margin-top: -53px; */
    font-size: 25px;
  }

  .exp-para {
    text-align: justify;
    word-spacing: 0px;
    font-size: 15px;
    line-height: 23px;

  }

  .pcmdheads {
    align-items: center;
    justify-content: center;
    font-size: 15px;
  }

  .platforms {
    font-size: 17px;
    line-height: 25px;
    /* margin-top: 47px; */
  }

  .pmt {
    margin-top: 20px;
  }

  

  .ind-boxhead {
    margin-left: 20px;
    font-weight: 600 !important;
    font-size: 16px;
}

  .ind-card-title {
    font-size: 14px !important;
    line-height: 20.5px;
    text-align: left;
  }

  .ind-card-text-3 {
    font-size: 10px;
    line-height: 14.8px;
    color: white;
    text-align: left;

  }

  .card-imgs {
    margin-left: -10px;

    /* padding-top: 4px; */
    width: 113% !important;
    height: 278px !important;
    margin-top: 6px;
    
  }

  /* .Built-back-colour-1 {
    height: 556px;
  } */

  .industries-play-bg-video{
    height: 264px;
  }
  .newaccede {
    margin-left: 17px;
    width: 50px !important;
    height: 15px !important;
  }

  .slokaenergy {
    margin-left: 6px;
    width: 85px !important;
  }

  .gbreco {
    padding-left: 6px;
    width: 84px !important;
  }

  .newsrdsystems {
    width: 73px !important;
  }

  .mpara {
    width: 100%;
    text-align: center;
    font-size: 15px;
    line-height: 25.5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .guidelines {
    /* width: fit-content; */
    font-size: 13px;
    line-height: 21.6px;
    padding-left: 10px;
  }

  .tasdiv{
    margin-top: 30px !important;
  }

  .tlnt-heading {
    font-size: 24px;
    line-height: 47px;
  }

  .tlnt-para {
    text-align: justify;
    font-size: 13px;
    line-height: 25.6px;

  }

  .tlnt-card-para {
    text-align: justify;
    /* margin-left: 107px; */
    font-size: 10px;
    line-height: 19.9px;
  }

  .tlnt-docreators {
    /* margin-left: 108px; */
    font-size: 19px;
  }

  .tlnt-btn {
    border: none;
    border-radius: 4px;
    padding: 10px 0px;
  
   
  }

  .tlnt-botn {
    padding: 10px 20px;
    font-size: 10px;
    color: white;
    background: rgb(26, 74, 185);
    border: none;
    
  }
  /* .dood {
    top: 62%;
   left: 81%;
    position: absolute;
} */
  .newlab {
    height: 161px;
    width: 320px;
  }

  .lab-img {
    display: none;
    /* height: 161px;
    width: 320px; */
  }

  .industries-bg-pink {
 
    margin-top: -40px;
    height: 224px !important;
    width: 320px !important;
  }

  .labimg .lab-data-industries {
    left: 15%;
  }

  .mb-2 {
    text-align: justify;
    font-size: 8px !important;
    line-height: 14px !important;
    margin-top: 5px !important;
  }

  .readmore {
    margin-top: 9px !important;
  }

  .benfits {
    padding-top: 90px;
    margin-top: 90px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
  }

  .benfits-para {
    font-size: 15px;
    line-height: 23.6px;
  }

 

  .lab-data-industries {
    width: 175px !important;
    /* left: 15%; */
  }

 

 

  .ind-platformspara {
    line-height: 18.6px;
    
    text-align: center;
    font-size: 12px;
  }

  .text-left {
    text-align: center !important;
  }

  .boxtext {
    font-size: 14px;
  }

  /* .card {
    width: 100% !important;
    margin-top: 0px !important;
  } */

  .faq {
    font-size: 10px;
    margin: 0px;
  }

  .card-bodys {
    font-size: 8px !important;
    text-align: justify;
  }

  .cardsdetails {
    font-size: 12px;
    /* margin-left: 72px !important; */
    color: white;
    text-align: center;
  }

  .ind-card-title {
    text-wrap: wrap;
    /* margin-left: -78px!important; */
  }

  .question {
    line-height: 14.8px;
    font-size: 8px;

  }

  .industries-pharama {
    width: 91%;
    height: 75%;
    padding-top: 28px;
    margin-left: 7px !important;

  }

  .longclinicaltrials {
    margin-left: 27px;
    width: 81%;
    height: 81%;
    padding-top: 28px;
}

.longmedicaldevices {
  width: 93%;
  height: 93%;
  padding-top: 28px;
  margin-left: 16px;
}

  .multiomics {
    width: 86%;
    height: 86%;
    padding-top: 28px;
    margin-left: 17px;
  }

  .tlnt-card-web-img {
    width: 100%;
    border-radius: 0px;
    /* margin-left: -15px; */
}

.bles-div{
  margin-top: 0px;
}


.doodpng{
  height: 69px;
}

.App-logo-industrie{
  height: 46px;
  width: 45px;
}

}

@media only screen and (min-width:769px) and (max-width:991px) {
 
  .indheading {
    width: 752px;
  }

  .mainimg {
    top: 0px;
    left: 17px;
  }

  .industies-mainhead {
    width: 636px;
    /* margin-left: -40px;
    margin-top: -133px; */
    font-size: 35px;
    font-weight: 500;
    line-height: 41.2px;
  }

  .industries-subhead {
    /* margin-left: -34px; */
    font-size: 15px;
    line-height: 23.6px;
    width: 485px;
    margin-top: 0px;

  }

  .button1 {
    margin-left: 11% !important;
    margin-top: 41px !important;
  }

  h5 {
    font-size: 15px !important;
  }

  .boxtext {
    font-size: 12px !important;
  }

  .platforms {
    font-size: 35px;
    font-weight: 500;
    line-height: 46px;
  }

  .platformspara {
    font-size: 16px;
    font-weight: 400;
  }

  .card-imgs {
    margin-top: 0px;
    margin-left: -11px;
    width: 105%;


  }

 

  .mpara {
    width: 745px;
    font-size: 29px;
    font-weight: 600;
    line-height: 50.5px;
  }

  .guidelines {
    font-size: 15px;
    line-height: 24.6px;
    /* width: 724px; */
  }

  .lab-data-industries {
    width: 423px !important;

  }

  .lab-img {
    height: 326px !important;
  }

  .card-industries {
    margin-top: 14px;
  }

  .tlnt-card {
    width: 104%;
    margin-left: -15px;
  }

  .tlnt-card-para {
    font-size: 14px;
    font-weight: 500;
    line-height: 27.9px;
  }

  .tlnt-docreators {
    font-size: 22px;

  }

  .tlnt-botn {
    padding: 13px 25px 13px 25px;
    font-size: 13px;
    margin-bottom: 26px;

  }

  .dood {
    top: 60%;
    left: 58%;
  }

  .doodpng {
    height: 93px;
  }

  .eng {
    margin-left: -18px;
  }

  .cardsdetails {
    color: white !important;
    font-size: 18px !important;
  }

  .tlnt-heading {
    font-weight: 600;
    font-size: 36px;
  }

  .benfits {
    font-size: 34px;
    font-weight: 600;
  }

  .platforms {
    font-size: 36px !important;
    font-weight: 600 !important;
  }

  .platformspara {
    font-size: 17px !important;
  }

  .industries-pharama {
    width: 91%;
    height: 75%;
    padding-top: 65px;
    margin-top: 80px;
  }

  .longclinicaltrials {
    margin-top: 37px !important;
    width: 86%;
    height: 81%;
    padding-top: 60px;
    margin-left: 20px;
  }

  .longmedicaldevices {
    width: 93%;
    height: 93%;
    padding-top: 182px;
    margin-left: 37px;
  }

  .multiomics {
    width: 72%;
    height: 86%;
    padding-top: 86px;
    margin-top: 66px;
    margin-left: 30px;
  }

  .card-bodys {
    font-size: 12px;
  }
.ind-card-text-3{
  color: white;
}

.tlnt-card-web-img {
  width: 100%;
  border-radius: 0px;
  margin-left: -15px;
  height: 100%;
}
}

@media only screen and (min-width:991px) and (max-width:1024px) {

  .mainimg { 
    top: 105px !important;
    left: 15px !important;
  }

  .industies-mainhead {
    color: #FFF;
    font-family: Poppins;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 58.2px;
    letter-spacing: -0.32px;
    display: flex;
    /* width: 759px; */
    /* height: 202px; */
    flex-direction: column;
    justify-content: center;
}

.industries-subhead {
  color: #FFF;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.6px;
  letter-spacing: -0.32px;
  margin-top: 15px;
  display: flex;
  width: 789px;
  /* height: 72px; */
  flex-direction: column;
  justify-content: center;
}

  .subhead {
    width: 560px;
    font-size: 17px;
    line-height: 26.6px;
  }

  .button1 {
    margin-left: 0% !important;
    margin-top: 0px;
  }

  .longclinicaltrials {
    margin-top: 24px;
    width: 91%;
    height: 91%;
    /* padding-top: 60px; */
}

  .longmedicaldevices {
    width: 84%;
    height: 84%;
    /* padding-top: 182px; */
    /* margin-left: 38px; */
    margin-top: 79px;
}

.industries-pharama {
  width: 91%;
  height: 91%;
  /* padding-top: 65px; */
  margin-top: 44px;
}

.multiomics {
  width: 90%;
  height: 84%;
  /* padding-top: 86px; */
  margin-top: 73px;
}

.business{
  width: 20%;
  height: 20%;
}

.ind-boxhead{
  font-size: 10px;
}
.boxtext {
  color: rgba(0, 0, 0, 0.70);
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 360;
  line-height: 19.6px;
  letter-spacing: -0.32px;
  text-align: inherit;
  /* width: 306px; */
}

.ind-card-title {
  color: #FFF;
  color: #FFF;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 27.5px;
  letter-spacing: -0.32px;
}

.card-imgs {
  margin-bottom: 17px;
  margin-left: -10px;
  border-radius: 20px;
}

.ind-card-text-3{
  color: #FFF;
}
.mpara {
  text-align: center;
  color: #050505;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 39.5px;
  letter-spacing: -0.32px;
}

.guidelines{
  width: fit-content;
}

.bles-data{
  font-size: 13px;
}
.tasdiv{
  margin-top: 20px !important;
}

.tlnt-card-web-img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  margin-left: -15px;
}
.eng {
  margin-top: 40px;
  margin-left: 0px;
}



.doodpng {
  height: 65px;
}

.tlnt-botn {
  padding: 16px 20px 16px 20px !important;
  font-size: 15px;
  color: white;
  border-radius: 4px;
  background: #1A4AB9;
  border: none;
}

.boxtext {
  color: rgba(0, 0, 0, 0.70);
  font-family: Poppins;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 360;
  line-height: 19.6px !important;
  letter-spacing: -0.32px;
  text-align: inherit;
  /* width: 306px; */
}

}