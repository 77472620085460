.contact {
  background-color: blue;
  color: white;
  padding: 5px 10px;
  border-radius: 6px;
  font-weight: 500;
  border: 1px solid blue;
}

.mainbar-nav {
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
  height: 64px;
}

/* .logo-main {
  width: 38px;
  height: 24px;
  margin-top: 15px !important;
  margin-right: 10px;
} */
.navbar-just-up {
  font-family: Roboto;
  font-weight: 500;
  font-size: 14px;
  margin-top: 8px;
  line-height: 130%;
  text-align: center;

}

.logo-text {
  margin-top: 10px;
}

.logo-main {
  width: 180px;
}

.navbar-just-up {
  justify-content: space-evenly;
}



.slide {
  width: 100%;
}

/* .expertise{
    margin-left: 50px;
} */
.homenavigateclass:hover {
  text-decoration: none;
}

.navlinksdropdown {
  margin-top: 15px;
  background-color: rgba(255, 255, 255);
  width: fit-content;
  height: fit-content;
  border-radius: 20px;
}

/* .navlinkgifclass{
  width: fit-content;
  height: fit-content;
} */
.navcard:hover {
  text-decoration: none;
}

.navcard2:hover {
  text-decoration: none;
}

.navcard2 .dropdown-item:hover {
  background-color: #074ff7 !important;
  color: white !important;
  border-radius: 10px !important;
}

.navcard2 .dropdown-item {
  font-size: 20px !important;
  /* color: grey; */
  font-weight: 500;
}

.comingsoonclass {
  /* display: flex;
  flex-direction: column; */
  /* margin: auto; */
}

/* .acceleratorsclass {
  color: rgba(0, 70, 232, 0.69);

  font-style: normal;
  font-weight: 500;
  line-height: 18.2px;
  border: 1px solid #0046E8;
 
  margin-right: 42px;
  background: #ECECEC;
} */


.acceleratorsclass-01 {


  background: #ECECEC;
  border: 1px solid #ECECEC;
  font-weight: 500;
  margin-right: 8px;
  color: #2B2B2B;
  outline: none !important;
  padding-left: 16px;
  padding-right: 16px;

}

.NavBAR-Contact-Us {

  padding-top: 5px;
  padding-bottom: 5px;
  color: white;
  background: #158AFF;
  border: 1px solid #158AFF;
  font-weight: 700;
  outline: none !important;
  line-height: 120%;
  font-family: Roboto;
  font-size: 14px;
  height: 40px;
  padding-left: 16px;
  padding-right: 16px;
  letter-spacing: 0.5px;
}

.navbar-angle {
  color: white;
  margin-left: 14px;
  font-weight: 700;
}

.navbar-angle-1 {
  margin-left: 14px;
  color: #2B2B2B;

}

.acceleratorsclass:focus-visible {
  outline: 0px !important;
}

.acceleratorsclass:focus {
  outline: 0px !important;
}

.icon-01 {
  margin-left: 5px;
}

.Dark-Text {
  font-weight: 700;
}

.navbar-light .navbar-nav .nav-link {
  padding-right: 12px;
}

.insights-width-parent {
  width: 668px !important;
  left: -270% !important;
}


.navBAR-Padding {
  padding-left: 0px !important;
}

.navbar-auto-left {
  margin: auto 0px;
  padding-top: 0px !important;
}

@media only screen and (min-width: 320px) and (max-width: 468px) {
  .navbar {
    padding: 0px;
  }

  .logo-main {
    width: 164px;
    margin-top: 14px;
  }

  .navbar-light .navbar-toggler {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .mobile-res {
    display: none !important;
    max-width: 1198px !important;
  }

  .expertise {
    margin-left: 0px !important;
  }

  .acceleratorsclass {
    margin-right: 0px;
    font-size: 10px;
    padding: 7px 7px !important;
    margin-bottom: 15px;
    margin-top: 10px;

  }

  .contact {
    margin-bottom: 20px !important;
  }

  .navbar-nav .dropdown-menu {
    transform: translateX(-2%) !important;
  }

  .insights-width-parent .insights-width {
    /* width: 0% !important; */
    flex-wrap: nowrap !important;

  }

  .navcard2 .dropdown-item {
    font-size: 6px !important;
  }

  .navlinkgifclass {
    width: 100px !important;
    height: 116px !important;
  }

  .navcard {
    margin-left: -20px !important;
  }

  .navcard2 {
    padding-left: 0px !important;
  }

  .navbar-nav .dropdown-menu {
    height: 146px;
    width: 200px !important;
    margin-bottom: 10px;
  }

  .navcard2.data-eng-pad {
    padding-left: 1px !important;
  }

  .logo {
    margin-left: 0px;
  }

  .n-w {
    width: 100px;
  }


}

@media only screen and (min-width: 468px) and (max-width: 768px) {
  .mobile-res {
    display: none !important;
  }

  .navbar-light .navbar-toggler {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .expertise {
    margin-left: 0px !important;
  }

  .navbar {
    padding: 0px;
  }

  .acceleratorsclass {
    margin-right: 0px;
    font-size: 10px;
    padding: 7px 7px !important;
    margin-bottom: 15px;
    margin-top: 10px;

  }

  .contact {
    margin-bottom: 20px !important;
  }

  .navbar-nav .dropdown-menu {
    transform: translateX(3%) !important;
  }

  .insights-width-parent .insights-width {

    flex-wrap: nowrap !important;

  }

  .navcard2 .dropdown-item {
    font-size: 9px !important;
  }

  .navlinkgifclass {
    width: 242px !important;
    height: 114px !important;
    margin-left: 35px !important;
  }

  .navcard {
    margin-left: -20px !important;
  }

  .navcard2 {
    padding-left: 0px !important;
  }

  .navbar-nav .dropdown-menu {
    height: 183px;
    width: 382px !important;
    margin-bottom: 10px;
  }

  .navcard2.data-eng-pad {
    padding-left: 1px !important;
  }

  .logo {
    margin-left: 0px;
  }

  .n-w {
    width: 100px;
  }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mobile-res {
    display: none !important;
  }

  .tab-NAV {
    display: flex !important;
  }

  .navbar-light .navbar-toggler {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .expertise {
    margin-left: 0px !important;
  }

  .navbar {
    padding: 0px;
  }

  .acceleratorsclass {
    margin-right: 0px;
    font-size: 10px;
    padding: 7px 7px !important;
    margin-bottom: 15px;
    margin-top: 10px;

  }

  .contact {
    margin-bottom: 20px !important;
  }

  .navbar-nav .dropdown-menu {
    transform: translateX(3%) !important;
  }

  .insights-width-parent .insights-width {

    flex-wrap: nowrap !important;

  }

  .navcard2 .dropdown-item {
    font-size: 9px !important;
  }

  .navlinkgifclass {
    width: 242px !important;
    height: 114px !important;
    margin-left: 35px !important;
  }

  .navcard {
    margin-left: -20px !important;
  }

  .navcard2 {
    padding-left: 0px !important;
  }

  .navbar-nav .dropdown-menu {
    height: 183px;
    width: 382px !important;
    margin-bottom: 10px;
  }

  .navcard2.data-eng-pad {
    padding-left: 1px !important;
  }

  .logo {
    margin-left: 0px;
    margin-top: 10px;
  }

  .n-w {
    width: 100px;
  }

}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mobile-res {
    display: block !important;
  }



  .navbar-light .navbar-toggler {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .expertise {
    margin-left: 0px !important;
  }

  .navbar {
    padding: 0px;
  }

  .acceleratorsclass {
    margin-right: 0px;
    font-size: 10px;
    padding: 7px 7px !important;
    margin-bottom: 15px;
    margin-top: 10px;

  }

  .contact {
    margin-bottom: 20px !important;
  }

  .navbar-nav .dropdown-menu {
    transform: translateX(3%) !important;
  }

  .insights-width-parent .insights-width {

    flex-wrap: nowrap !important;

  }

  .navcard2 .dropdown-item {
    font-size: 9px !important;
  }

  .navlinkgifclass {
    width: 242px !important;
    height: 114px !important;
    margin-left: 35px !important;
  }

  .navcard {
    margin-left: -20px !important;
  }

  .navcard2 {
    padding-left: 0px !important;
  }

  .navbar-nav .dropdown-menu {
    height: 183px;
    width: 382px !important;
    margin-bottom: 10px;
  }

  .navcard2.data-eng-pad {
    padding-left: 1px !important;
  }

  .logo {
    margin-left: 0px;
    margin-top: 10px;
  }

  .n-w {
    width: 100px;
  }

}