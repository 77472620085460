body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', '', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @import url('https://fonts.googleapis.com/css2?family=+Condensed:wght@500;700&display=swap');

}

code {
  font-family: 'Poppins', sans-serif;
}


/* 
.roboto-condensed-<uniquifier> {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
} */

.variable-roboto-condensed {
  font-family: "Roboto Condensed", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
