.Product-landing-Redirect {
    display: flex;
    height: 58px;

    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: 1px solid rgba(0, 0, 0, 0.04);
    background: #2B2B2B;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.04);
    position: fixed;
    top: 0%;
    width: 100%;
    z-index: 1000;
}


.landing-ProudcT {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.financial-Landing-02 {
    color: #ECECEC;
    font-family: Merriweather;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30.493px;
    /* 190.581% */
    letter-spacing: 0.392px;
    margin-bottom: 0px;
    text-decoration: underline;
}


.financial-nav-heading {
    /* Financial Services */




    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    /* identical to box height, or 114% */
    display: flex;
    align-items: center;

    color: #ECECEC;

    margin-bottom: 0px !important;
    /* Inside auto layout */
    flex: none;
    order: 3;
    flex-grow: 0;

}

.indrdt1-height {
    width: 100%;
    object-fit: cover;
    height: 570px;


}





.financial-height::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: -15px;
    bottom: 0px;
    background-color: #2b2b2b;
    width: 100%;
    mix-blend-mode: color;
    opacity: 0.8;
}

.finance-4-h1 {
    color: #FFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-family: Roboto Condensed;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 86.4px */
    letter-spacing: -0.32px;
    text-transform: uppercase;
}

.gradient-layer-finance::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(43, 43, 43, 0.1) 0%, #2B2B2B 100%);
    width: 100%;


}


.percentage-number {
    /* 80% */

    width: 59px;
    height: 43px;

    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    /* or 43px */
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    color: #158AFF;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.finance-bg {
    background-color: #EDEDED;
}

.finance-paragraph {
    /* accelerate data processing speeds, enabling financial institutions to handle large volumes of data in real-time. */



    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 200%;
    /* or 36px */
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;

    color: #2B2B2B;


    /* Inside auto layout */

    order: 1;
    flex-grow: 0;

}

.width-increase {
    max-width: 1326px !important;
}

.financial-padding {
    padding: 50px 0px 50px 0px;
}

.service-Finance {
    /* Financial Service */

    margin: 0 auto;


    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    /* or 43px */
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    color: #2B2B2B;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.finance-craft {
    /* Frame 2147223720 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;




    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 200%;
    /* or 36px */
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;

    color: #2B2B2B;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.financial-padding-01 {
    padding: 100px 0px 100px 0px;
}

.finance-disc {
    list-style-type: disc !important;
}

.disc-dot {
    font-size: 30px;

}


.banking-finance {
    /* text-align: justify; */
    color: rgba(43, 43, 43, 0.80);
    font-family: Merriweather;
    margin-bottom: 24px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
    letter-spacing: -0.32px;
    width: 100%;
    margin-left: 10px;
    padding-top: 10px;
}

/* .disc-banking {
    margin-left: 10px;
} */

.new-text-finance::after {
    content: "";
    border-right: 2px solid #505050;
    height: 7rem;
    margin-right: 23px;
    display: block;

    margin-top: 9px;


}

.new-text-finance-two::after {
    content: "";
    border-right: 2px solid #505050;
    height: 7rem;
    margin-right: 28px;
    display: block;

    margin-top: 9px;


}



.pro-data-finance-one {
    display: flex;
    justify-content: center;
    margin-right: 5%;


}

.pro-data-finance-two {
    display: flex;
    justify-content: center;
    margin-right: 0%;


}

.pro-data-finance-three {
    display: flex;
    justify-content: center;
    margin-right: 0%;


}

.new-text-finance-01::after {
    content: "";
    border-right: 2px solid #505050;
    height: 7rem;
    margin-right: 27px;
    display: block;
    margin-top: 9px;


}

.text-Foremost-finance {
    max-width: 62% !important;
    margin-top: 9px;
}

.banking-Ai {
    text-align: center;
    color: #2B2B2B;
    font-family: Roboto Condensed;
    padding-top: 100px;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 43.2px */
    letter-spacing: -0.32px;
    text-transform: uppercase;
    padding-bottom: 56px;
}


.financE-service {
    /* Services */



    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    /* or 43px */
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    color: #ECECEC;
    justify-content: center;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.background-finance-color {
    background-color: #2B2B2B;

}

.Solutions-last-div {
    padding-bottom: 100px;
}

.enterprises-paragraph {
    /* Enabling financial services and retail banking enterprises with AI and analytics throughout the entirety of the customer lifecycle journey. */



    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 200%;
    /* or 36px */
    display: flex;
    align-items: center;

    color: #ECECEC;

    justify-content: center;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.data-finance-image {
    margin: auto;
}

.finance-background-color {
    background-color: #383838;
    padding: 80px 80px;
    margin-bottom: 50px;
}

.Data-engineer-text-01 {
    /* Data Engineering */



    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 150%;
    /* identical to box height, or 54px */
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    color: #ECECEC;


    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

}

.data-sector-paragraph-01 {
    /* DataObserve empowers the financial services sector through extensive data engineering and modernization services, fostering insights creation and adoption across all decision-making levels. Our solutions emphasize cost reduction, avoidance of technical debt, and long-term sustainability. */



    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 200%;
    /* or 36px */
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;

    color: rgba(236, 236, 236, 0.7);


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.FInance-engineering-image {
    margin: auto;
    padding-left: 7%;
    padding-right: 5%;
}

.SERVICE-Retail {
    padding-top: 100px;
    padding-bottom: 100px;
}

.service-life-finance {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-bottom: 50px;
}


.financE-service-01 {
    /* sOLUTIONS */



    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    /* or 43px */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.32px;
    text-transform: uppercase;
    justify-content: center;
    color: #2B2B2B;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.enterprises-paragraph-01 {
    /* We prioritize guiding you towards the right course of action, eliminating uncertainties, and fostering confident progress through the integration of AI and analytics capabilities. */



    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;

    color: #2B2B2B;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}

.landing-Product-over-pad-finance {
    background: rgba(236, 236, 236, 0.93);

    position: relative;
    transition: all .5s ease-in;
    height: 480px;
    padding: 24px 33px 245px 24px;
    align-items: center;
    flex-shrink: 0;
    margin-right: 22px;
    max-width: 98.32% !important;
    cursor: pointer;
    margin-bottom: 50px;

}

.product-elevate-solution-financial {
    color: rgba(43, 43, 43, 0.80);
    font-family: Merriweather;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%;
    /* 36px */
    letter-spacing: -0.32px;
    padding-left: 0px !important;
}

.depend-proDuct-financial {
    color: #ECECEC;

    font-family: Merriweather;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 200%;
    /* 36px */
    letter-spacing: -0.32px;
    padding-left: 0px;
}

.landing-Product-over-pad-finance:hover .overlay-product {
    /* height: 100%; */
    opacity: 1;
}


.landing-Product-over-pad-finance:hover .Product-land-360 {
    opacity: 0;
}

.landing-Product-over-pad-finance:hover .product-elevate-solution-financial {
    opacity: 0;
}


.Landing-building-image {
    position: relative;
}

.industries-height-finance-02::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #2b2b2b;
    width: 100%;
    /* mix-blend-mode: color; */
    opacity: 56%;
}

.gradient-layer-finance-01::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #FFFFFF 100%);
    width: 100%;


}


.Advantage-do {
    /* The DATAOBSERVE advantage */



    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    /* or 43px */
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    color: #2B2B2B;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.do-analytics-banking {
    /* Frame 2147223727 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 21px;

    margin: 0 auto;
    width: 499px;
    height: 108px;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;


    /* Frame 2147223720 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    width: 506px;
    height: 108px;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;


    /* Explore how DataObserve's pioneering data-driven banking analytics solutions enable clients to provide personalized and secure customer experiences. */



    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 200%;
    /* or 36px */
    display: flex;
    align-items: center;

    color: #2B2B2B;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.advantage-Padding {
    padding-top: 100px;
    padding-bottom: 100px;
}


.speed-finance {
    /* Accelerate speed to value */



    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 120%;
    /* or 43px */
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    color: #2B2B2B;


    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;

}


.speed-paragraph {
    /* div.body-text */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 1px 0px 0px;

    width: 630px;
    height: 144px;


    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;


    /* Through harnessing our arsenal of 30+ customer tools and accelerators tailored for the financial services industry, we deliver value 50% faster than off-the-shelf solutions. */



    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 200%;
    /* or 36px */
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;

    color: rgba(43, 43, 43, 0.9);


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.value-Speed {
    margin-bottom: 18px;
}

.speed-margin-finance {
    margin-bottom: 57px;
}

.speed-margin-finance-01 {
    margin-bottom: 100px;
}


.indrdt1-height {
    width: 100%;
    object-fit: cover;
    height: 570px;


}

.video-finance-last {
    object-fit: cover;
    width: 100%;
    height: 968px;
}

.video-finance-last-1 {
    object-fit: cover;
    width: 300px;
    height: 968px;
}


.explore-industries-finance {
    /* Explore Industries */



    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 18px;
    /* identical to box height, or 130% */
    display: flex;
    align-items: center;

    color: #FFFFFF;


    /* Inside auto layout */


}

.bg-color-finance {
    background: rgba(21, 138, 255, 1) !important;
    height: 970px;

}

.video-color-bg-angle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-angle-finance {
    margin-left: 10px;
    width: 24px;
    height: 24px;
    font-size: 14px;
    color: rgba(255, 255, 255, 1);

}

.explore-finance-heading {
    color: rgba(255, 255, 255, 1);
    /* Explore Industries */



    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 130% */
    display: flex;
    align-items: center;

    color: #FFFFFF;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;


}



.gradient-layer-finance-lastone::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(180deg, rgba(43, 43, 43, 0.6) 60%, #2B2B2B 100%);
    width: 100%;


}

.finanical-overlay {
    position: relative;
    height: 970px;
}

.fianacial-height::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: -15px;
    bottom: 0px;
    background-color: #2b2b2b;
    width: 100%;
    mix-blend-mode: color;
    opacity: 0.8;
}

.Financial-Data {
    position: absolute;
    top: 43%;
    bottom: 0%;
    left: 2%;

}

.sideBar-Scroll-MENU {
    /* Frame 2147223965 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 0px 4px 0px;
    gap: 10px;



    border-left: 2px solid rgba(218, 218, 218, 1);
    height: 216px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;


    /* DO Insights */



    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */
    display: flex;
    /* align-items: center; */
    letter-spacing: -0.32px;
    text-transform: uppercase;

    color: rgba(43, 43, 43, 0.5);

    flex-direction: column;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}


.sideBar-Scroll-MENU li a.active {
    color: #0275B8 !important;
    border-left: 2px solid rgba(21, 138, 255, 1);
    height: 26px;
    margin-left: -2px;

}





.sideBar-Scroll-MENU.sideBar-Scroll-MENU {
    list-style-type: none !important;
}



.sidebar-Top-ONE {
    padding-top: 50%;
    position: sticky;
    top: 0%;

}






.gaming-heading-finance {
    /* GAMING */



    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 120%;
    /* or 43px */
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;
    text-transform: uppercase;

    color: #FFFFFF;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}

.gaming-paragraph-finance {
    /* Explore a purposeful career journey within a collaborative setting. */


    font-family: 'Merriweather';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    /* or 27px */
    display: flex;
    align-items: center;
    letter-spacing: -0.32px;

    color: #FFFFFF;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;

}



.sideBar-Scroll-MENU a {
    color: gray;
    /* position: relative;
    display: inline-block;
    vertical-align: top; */
}

.finance-active:hover {
    color: grey;
    text-decoration: none;
}

.sidebar-Top-ONE ul #insights.active {
    font-weight: bold;
    /* Example: bold font for active item */
    color: #fff;
    /* Example: change text color for active item */
}


.financial-build-Image {
    width: 100%;
    border-radius: 0%;
}



.revolutioN-banking {
    justify-content: center;
}


.landing-page-what-we-do-02-finance {
    background: #2B2B2B;
    width: 100%;
    margin-top: 100px;
    position: relative;


}