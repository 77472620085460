.text-Foremost-industrial {
    max-width: 63% !important;
    margin-top: 9px;
}


.text-Foremost-industrial-01 {
    max-width: 68% !important;
    margin-top: 9px;
}

.text-Foremost-industrial-02 {
    max-width: 72% !important;
    margin-top: 9px;
    padding-left: 32px;
}



.pro-data-industrial-two {
    display: flex;
    justify-content: center;
    margin-right: 7%;


}

.pro-data-industrial-three {
    display: flex;
    justify-content: center;
    margin-right: 7%;


}

.tick-HOME-industrial-01 {
    width: 37px;
    height: 37px;
    margin-left: 28px;
    margin-top: 12px;
}

.tick-HOME-industrial-02 {
    width: 37px;
    height: 37px;
    margin-left: 19px;
    margin-top: 12px;
}

.tick-HOME-industrial-03 {
    width: 37px;
    height: 37px;
    margin-left: 38px;
    margin-top: 12px;
}


.new-text-industrial-01::after {
    content: "";
    border-right: 2px solid #505050;
    height: 11rem;
    margin-right: 12px;
    display: block;
    margin-top: 9px;
}

.new-text-industrial-02::after {
    content: "";
    border-right: 2px solid #505050;
    height: 11rem;
    margin-right: 12px;
    display: block;
    margin-top: 9px;
}


.Detect-resolve h1 {
    color: #2B2B2B;
    font-family: Roboto Condensed;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.32px;
    text-transform: uppercase;
}

.trade-promo a {
    font-size: 14px !important;
}


.Industrie-spot{
    margin-bottom: 100px;
}

.sideBar-Scroll-MENU li a.active {
    color: #0275B8 !important;
    border-left: 2px solid rgba(21, 138, 255, 1);
    height: 26px;
    margin-left: -2px;

}



