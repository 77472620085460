.pro-data-cpg-two {
    display: flex;
    justify-content: center;
    margin-left: 10%;


}

.pro-data-cpg-three {
    display: flex;
    justify-content: center;
    margin-left: 10%;


}


.new-text-cpg-two::after {
    content: "";
    border-right: 2px solid #505050;
    height: 7rem;
    margin-right: 16px;
    display: block;
    margin-top: 9px;


}


.tick-HOME-cpg {
    width: 37px;
    height: 37px;
    /* margin-left: 15px; */
    margin-top: 12px;
}

.tick-HOME-cpg-01 {
    width: 37px;
    height: 37px;
    margin-left: 19px;
    margin-top: 12px;
}



.Detect-resolve h3 {
    color: #2B2B2B;
    font-family: Roboto Condensed;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.32px;
    text-transform: uppercase;
}