.art-back-btn {
  /* Frame 2147224068 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  width: 60px;
  height: 36px;

  background: #ECECEC;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  border: none;
  outline: none !important;

}

.article-top-back {
  /* div.nav_container */
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;

  /* Auto layout */
  /* display: flex;
flex-direction: column; */
  /* justify-content: center; */
  /* align-items: flex-start; */
  /* padding: 0px 0px 0px 275px; */

  /* position: absolute;
width: 1724px;
height: 64px;
left: 0px;
top: 20px; */

  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 1px 1px 65px rgba(0, 0, 0, 0.05);

}

.article-sec-1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 10px 100px;
  gap: 24px;
  background: #ECECEC;
}

.article-sec-1-div {
  text-align: center;
}

.article-do-logo {
  width: 168px;
  margin-bottom: 24px;
}

.article-sec1-h1 {
  font-size: 36px;
  margin-bottom: 24px;
  height: 68px;

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 67px;
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;
  text-transform: uppercase;

  color: #2B2B2B;

  flex: none;
  order: 0;
  flex-grow: 0;

}

.article-sec1-btn {

  color: #fff;
  box-sizing: border-box;
  height: 38px;
  background: #158AFF;
  border: 1px solid #F2F2F2;
  border-radius: 100px;
  align-items: center;
  width: 106px;
  height: 38px;
  outline: none;
  margin-bottom: 24px;
  outline: none !important;

}

.article-sec1-p {
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;
  height: 20px;

}

.article-sec-2 {
  margin-top: 50px;
  margin-bottom: 100px;
}

.lastupdate {
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  align-items: center;
  letter-spacing: -0.06px;
  color: #242424;


}

.dots-3 {
  border-radius: 1.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 23px;
}

.article-sec-2-h1s {
  height: 30px;
  left: 0px;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  font-size: 21.375px;
  line-height: 30px;
  display: flex;
  align-items: center;
  letter-spacing: -0.384px;

  color: #242424;


}

.article-img-1 {
  height: 100%;
  width: 100%;
  background: #EAEAEA;

}

.article-sec2-div1-p {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;

  display: flex;
  align-items: center;
  letter-spacing: -0.06px;
  color: #242424;
}

.li-16 {
  list-style-type: none;
}

.li-s {
  text-decoration: underline;
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.li-s-d {
  text-decoration: underline;
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  background-color: #E8F3E8;
  display: inline;
}

.li-16 li {
  margin-bottom: 15px;
}

.li-16 .li-s-d .interface-Elements {
  margin-bottom: 15px;
}

.li-s-d span {
  margin-bottom: 15px;
}

.li-mar {
  margin-top: 15px;
}

.article-img-3 {
  height: 100%;
  width: 100%;
  background: #EAEAEA;
}

.article-sec-3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 10px 100px;
  gap: 24px;
  /* height: 1055.18px; */
  
  background: #ECECEC;
}

.btn-1div {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.article-whole-btns {
  border: none;
  background: #F2F2F2;
  border: 1px solid #F2F2F2;
  border-radius: 100px;
  width: 110px;
  height: 38px;
  padding: 5px 23px 5px 6px;
}



.Discover-Marking {
  height: 20px;
  left: 0px;
  top: 0px;
  margin-top: 48px;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-size: 14.25px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #242424;
}

.Discover-Marking {
  height: 20px;
  left: 0px;
  top: 0px;
  margin-top: 48px;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-size: 14.25px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #242424;
}


.more-buttons {
  box-sizing: border-box;
  height: 38px;
  background: #F2F2F2;
  border: 1px solid #F2F2F2;
  border-radius: 100px;
}

.article-sidebar-div {
  position: sticky;
  top: 0px;
}

.article-sidebar {
  list-style-type: none;
  border-right: 1px solid #dadada;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  align-items: center;
  letter-spacing: -0.384px;
  color: #474747;
  width: 400px;
  padding-left: 0px !important;
}

.article-sidebar li a.active {
  font-weight: 400;
  color: #158AFF;
  align-items: center;
  justify-items: center;
  display: flex;
  text-align: center;
  box-sizing: border-box;
  /* width: 400px; */
  height: 54.59px;
  background: #EEF7FF;
  border-right: 4px solid #158AFF;
}

.article-sidebar a {
  color: #474747;

}

.article-sidebar-span {
  margin-left: 20px;
}

.article-rltd-ele {
  font-weight: 300;
  align-items: center;
  justify-items: center;
  display: flex;
  text-align: center;
  box-sizing: border-box;
  height: 54.59px;
}

.article-consistent {
  font-weight: 300;
  align-items: center;
  justify-items: center;
  display: flex;
  text-align: center;
  box-sizing: border-box;
  height: 54.59px;
}

.article-topic1 {
  font-weight: 300;
  align-items: center;
  justify-items: center;
  display: flex;
  text-align: center;
  box-sizing: border-box;
  height: 54.59px;
}

.article-topic2 {
  font-weight: 300;
  align-items: center;
  justify-items: center;
  display: flex;
  text-align: center;
  box-sizing: border-box;
  height: 54.59px;
}

.article-topic3 {
  font-weight: 300;
  align-items: center;
  justify-items: center;
  display: flex;
  text-align: center;
  box-sizing: border-box;
  height: 54.59px;
}

.article-topic4 {
  font-weight: 300;
  align-items: center;
  justify-items: center;
  display: flex;
  text-align: center;
  box-sizing: border-box;
  height: 54.59px;
}

.article-topic5 {
  font-weight: 300;
  align-items: center;
  justify-items: center;
  display: flex;
  text-align: center;
  box-sizing: border-box;
  height: 54.59px;
}

.art-sidebar-images {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 2px solid #C0C0C0;
  border-bottom: 2px solid #C0C0C0;
  width: 400px;
}

.article-hand {
  width: 30px;
  height: 30px;
}

.article-only26k {
  width: 30px;
  height: 30px;
  margin-left: 2px;
}

.article-comment {
  width: 30px;
  height: 30px;
  margin-left: 20px;
}

.article-only244 {
  width: 24px;
  height: 16px;
  margin-left: 2px;
}

.article-save {
  width: 25px;
  height: 25px;
  margin-left: 10px;
}

.article-mediaplay {
  width: 28px;
  height: 40px;
  margin-left: 10px;
}

.article-upload {
  width: 28px;
  height: 40px;
  margin-left: 10px;
}





.trending-list-1 {
  display: flex;
  margin-top: 48px;
}

.ending-width {
  max-width: 100% !important;
  padding-left: 0px;
}

.blogs-Trending-logo {
  /* Section → Link → 1*JeaJWN5uIwe4UqSESIP4Nw.jpeg */

  width: 200px;
  height: 134px;

  /* background: url(.jpg); */

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

}

.article-Marking-insigh-text {
  /* Intro to LLM Agents with Langchain: When RAG is Not Enough */


  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  /* or 27px */
  display: flex;
  align-items: center;

  color: #242424;


}

.insigh-text-Marking {

  /* Sir Tim Berners-Lee’s open letter to mark the occasion of the Web’s 35th Birthday. */

  /* position: absolute; */
  width: 472.68px;
  height: 18px;
  left: 0px;
  top: -0.5px;

  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-size: 14.125px;
  line-height: 20px;
  /* or 142% */
  display: flex;
  align-items: center;

  color: #6B6B6B;
}

.article-more-form {

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 67px;
  /* identical to box height, or 187% */
  /* display: flex; */
  align-items: center;
  letter-spacing: -0.32px;
  text-transform: uppercase;

  color: #2B2B2B;


  /* Inside auto layout */


}

.article-btn-dark-domain {
  border: none;
  outline: none;

  width: 46px;
  height: 20px;

  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-size: 11.5781px;
  line-height: 20px;
  /* identical to box height, or 173% */
  /* display: flex; */
  align-items: center;

  color: #158AFF;


  /* Inside auto layout */
  /* flex: none;
  order: 0;
  flex-grow: 0; */

}

.Marking-ending {
  display: flex;
  justify-content: space-between;
}

.insigh-text-date-mar {
  margin-right: 15px;
  height: 20px;
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-size: 11.5781px;
  line-height: 20px;
  /* identical to box height, or 173% */
  display: flex;
  align-items: center;

  color: #6B6B6B;


  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

}

.insigh-text-read-min {
  /* Section → 5 min read */



  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-size: 11.5781px;
  line-height: 20px;
  /* identical to box height, or 173% */
  display: flex;
  align-items: center;

  color: #6B6B6B;


  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;

}