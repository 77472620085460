.ind-lifesub-sec-1 {
  position: relative;
}

.ind-lifesub-video1 {
  width: 100%;
}

.life-science-sub {
  position: absolute;
  top: 43%;
  left: 0%;
  right: 25%;
  width: 796px;
  height: 43px;
}

.dop-1 {
  object-fit: cover;
  width: 100%;
  height: 570px;
}

.life-science-sec-1 {
  color: #ffffff;
  padding: 0px;
  gap: 10px;
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: -0.32px;
}

.sec-1-para {
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;
  font-family: Merriweather;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
}

.logo-svg {
  margin-top: -32%;
  margin-left: 4%;
}

/* section-2 css */
.percentages-sec-2 {
  background: #ededed;
  margin-top: -1%;
  font: Roboto Condensed;
  font-size: 36px;
  font-weight: bold;
}

.sec-2-para {
  font-family: Merriweather;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  color: #2b2b2b;
  height: 188px;
  margin-top: -10px;
}

.percentages {
  color: #158aff;
  margin-top: 48px;
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  width: 59px;
  height: 43px;
}

.sec-2-main {
  margin-top: 10px;
  padding-left: 1%;
  max-width: 1464px;
}

.main-para-sec-2 {
  background: #ededed;
  margin: 0px 20px;
}

/* section 3 css */
.lif-exp-main {
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  margin-left: 16%;
  margin-top: 3%;
}

.head-main {
  display: flex;
}

.lif-science-para {
  font-family: Merriweather;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  margin-top: 41px;
  margin-left: 22%;
}

.demo-btn {
  color: #ededed;
  background-color: #158aff;
  height: 40px;
  width: 156px;
  font-family: roboto;
  border: none;

  margin-top: 11%;
  margin-left: -489px;
}

.svg-demo {
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 10px;
  margin-left: 10px;
  margin-top: 1px;
}

/* section 4 css */


.why-l-s {
  margin-top: 3%;
  background: #ededed;
  height: fit-content;
  padding-bottom: 100px;
}

.why-d-l-s-para {
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: -0.3199999928474426px;
  text-align: center;
  line-height: 120%;
}

.adv-bus-int-para {
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  color: #158aff;
  margin-left: -3%;
}

/* .adv-5 {
    font-family: Merriweather;
    font-size: 18px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.3199999928474426px;
  
    font-family: Merriweather;
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -0.3199999928474426px;
  
    margin-left: 29%;
    margin-top: -20%;
  } */
.advanced {
  text-align: left;
  color: #158aff;
  font-family: Roboto Condensed;
  margin-bottom: 10px;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 43.2px */
  letter-spacing: -0.32px;
  text-transform: uppercase;
}

.five-items {
  font-family: Merriweather;
  font-size: 17px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  padding-left: 30px;
}

.adv-new-text::after {
  content: "";
  border-right: 2px solid #505050;
  height: 25rem;
  margin-right: 32px;
  display: block;
  /* margin-top: -11px; */
  margin-top: 13px;
}


.adv-new-text2::after {
  content: "";
  border-right: 2px solid #505050;
  height: 25rem;
  margin-right: 32px;
  display: block;
  /* margin-top: -11px; */
  margin-top: 9px;
}

.adv-new-text2-entertain::after {
  content: "";
  border-right: 2px solid #505050;
  height: 25rem;
  margin-right: 24px;
  display: block;
  /* margin-top: -11px; */
  margin-top: 9px;
}

.adv-new-text-dynamic::after {
  content: "";
  border-right: 2px solid #505050;
  height: 8rem;
  margin-right: 30px;
  display: block;
  /* margin-top: -11px; */
}

.adv-pro-data {
  display: flex;
  /* margin-top: -34px; */
  justify-content: center;
}

.adv-pro-data-entertain {
  display: flex;
  margin-left: 13px;
  justify-content: center;
}

.adv-pro-data-2 {
  display: flex;
  /* margin-left: 25px; */
  justify-content: center;
}

.adv-text-Foremost {
  max-width: 56% !important;
  margin-top: 9px;
}

.adv-tick-HOME {
  width: 38px;
  height: 38px;
  margin-left: 15px;
  margin-top: 12px;
}

.adv-supply-HOME {
  width: 38px;
  height: 38px;
  margin-left: 0px;
  margin-top: 4px;
}

.adv-decental-HOME {
  width: 38px;
  height: 38px;
  margin-left: 15px;
  margin-top: 10px;
}

/* section 5 css */
.dev-data-sol-para-1 {
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: -0.3199999928474426px;
  text-align: center;
  padding-top: 100px;
}

.our-emp-para-2 {
  font-family: Merriweather;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: -0.3199999928474426px;
  text-align: center;
  padding-bottom: 100px;
}

.dev-data-sub-para {
  background: #ededed;
  margin-top: 6%;
  padding: 2%;
  height: 429px;
  width: 1197px;
  margin-left: 10%;
}

.mainn-paraa {
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
}

.subb-paraa {
  font-family: Merriweather;
  font-size: 18px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  color: #2b2b2bb2;
}

/* section 6 css */
.enh-ind-stand-main {
  margin-top: 5%;
  position: relative;
}

.img-black {
  height: 1005px;
  width: 100%;
}

.enh-ind-para-main {
  position: absolute;
  /* margin-top: -23%; */
  /* margin-top: -36%; */
  /* margin-left: 8%; */
  top: 10%;

  color: #2b2b2b99;
}

.enh-para-1 {
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: -0.3199999928474426px;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
}

.enh-para-2 {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;

  color: #FFFFFF;
}

.cont-us-btnn {
  color: #ededed;
  background-color: #158aff;
  height: 40px;
  width: 122px;
  gap: 4px;
  font-family: roboto;
  border: none;
  cursor: pointer;
  margin-left: 40%;
}

/* section 7 css */
.sec-7-mainn {
  margin-top: 4%;
}

.dms-para-1 {
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  height: 129px;
}

.tfr-para-2 {
  font-family: Merriweather;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  height: 216px;
}

/* .dms-tfr {
    display: flex;
    justify-content: space-evenly;
  } */
.find-acce {
  margin-top: 4%;
  margin-left: 9%;
}

.findable {
  width: 100px;
  height: 100px;
  padding: 10px, 9.83px, 10px, 10.17px;
  gap: 10px;
  margin-bottom: 18px;
}

.finda-para {
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: left;
  width: 146px;
  height: 43px;
}

.dic-para {
  font-family: Merriweather;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
}

.acce-sable {
  margin-top: -17%;
  margin-left: 58%;
}

.accessable {
  width: 100px;
  height: 100px;
  padding: 10px, 9.83px, 10px, 10.17px;
  gap: 10px;
  margin-bottom: 18px;
}

/* SECTION 8 CSS */
.overall-sec-8 {
  width: 100%;
  height: 600px;
  margin-top: 7%;
  background: #2b2b2b;
}

.ocs {
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 189px;
  letter-spacing: -0.3199999928474426px;
  text-align: center;
  width: 280px;
  height: 43px;
  margin-left: 9%;
  color: #ececec;
}

.ocs-2 {
  width: 657px;
  height: 373px;
  background: #ffffff33;
  margin-top: 8%;
  margin-left: 9%;
  display: flex;
  justify-content: space-evenly;
}

.ba-unni-para {
  font-family: Roboto Condensed;
  font-size: 36px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  color: #ececec;
  width: 502px;
  height: 129px;
  margin-top: -25%;
  margin-left: 55%;
}

.learn-how-para {
  font-family: Merriweather;
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.3199999928474426px;
  text-align: left;
  width: 502px;
  height: 108px;
  color: #ececec;
  margin-top: 1%;
  margin-left: 55%;
}

.reead-btn {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  background: #158aff;
  color: #ffffff;
  border: none;
  width: 86px;
  height: 40px;
  margin-left: 55%;
}

.reead-svg {
  width: Fixed (12px);
  height: Hug (7px);

  margin-left: 7%;
  margin-top: -1px;
}


/* changes */


.sec-01-main::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: -15px;
  bottom: 0px;
  background-color: #2b2b2b;
  width: 100%;
  mix-blend-mode: color;
  opacity: 0.8;
}

.Life-01-main::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: linear-gradient(180deg, rgba(43, 43, 43, 0.1) 0%, #2B2B2B 100%);
  /* transform: rotate(90deg); */
  width: 100%;
}

.list-sub-1 {
  font-weight: 500;
  margin-bottom: 20px;
}

.list-sub-11 {
  font-size: 500;
  margin-bottom: 30px;
}

/* .box-product-landings {
  margin-bottom: 40px;
  margin-left: 0px !important;
  margin-right: 0px !important;
} */

/* .landing-Product-over-pad {
  background: rgba(236, 236, 236, 0.93);
  height: 403px;
  position: relative;
  transition: all .5s ease-in;
  height: 429px;
  padding: 24px 33px 245px 24px;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  max-width: 100% !important;
  cursor: pointer;

} */

.products-cards-land {
  /* display: block; */
  width: 100%;
  height: auto;
  z-index: 1;
}

.Product-land-360 {
  color: #2B2B2B;
  font-family: Roboto Condensed;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 54px */
  letter-spacing: -0.32px;
  text-transform: uppercase;
}

.product-elevate-solution {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 200%;
  /* or 36px */
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;

  color: rgba(43, 43, 43, 0.7);


  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.overlay-product {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background: rgba(21, 138, 255, 0.93);
  visibility: none;
  padding: 24px 33px 129px 24px;
  z-index: 2;
}

.Product-land-360degree {
  color: #ECECEC;
  font-family: Roboto Condensed;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 36px */
  letter-spacing: -0.32px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-do-360-product {
  color: #ECECEC;
  font-family: Roboto Condensed;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 36px */
  letter-spacing: -0.32px;
  text-transform: uppercase;
}

.Detect-resolve h4 {
  color: #2B2B2B;
  font-family: Roboto Condensed;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 54px */
  letter-spacing: -0.32px;
  text-transform: uppercase;
}

.depend-proDuct {
  color: rgba(43, 43, 43, 0.80);
  font-family: Merriweather;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 200%;
  /* 36px */
  letter-spacing: -0.32px;
  padding-left: 0px !important;
}

.advantage-Padding-Life {
  padding-top: 100px;
  padding-bottom: 100px;
  margin-bottom: 100px;
}

.Advantage-do {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;
  text-transform: uppercase;
  color: #2B2B2B;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.do-analytics-banking-Life {
  /* Frame 2147223727 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 21px;

  margin: 0 auto;



  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;


  /* Frame 2147223720 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;



  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 200%;
  display: flex;
  align-items: center;
  color: #2B2B2B;
  flex: none;
  order: 0;
  flex-grow: 0;

}



.gaming-heading-lifescience {
  /* GAMING */



  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  /* or 43px */
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;
  text-transform: uppercase;

  color: #FFFFFF;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

}



.financial-padding-enter-01 {
  padding: 80px 0px 80px 0px;
}

.service-Finance-enter {
  /* Financial Service */

  margin: 0 auto;


  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
  /* or 43px */
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;
  text-transform: uppercase;

  color: #2B2B2B;


  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

}

.finance-craft-enter {
  /* Frame 2147223720 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;


  /* Crafting actionable insights and yielding concrete outcomes within the financial services sector. */



  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 200%;
  /* or 36px */
  display: flex;
  align-items: center;
  letter-spacing: -0.32px;

  color: #2B2B2B;


  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

}