.bg-color{
    background-color: #1A4AB9;
    height: 418px;
    padding: 60px 0px 0px 0px;
  }
  .footercardbody{
    background-color: #1A4AB9;
    height: 327px;
    padding: 15% !important;

  }
  .ttuclass .card{
    border-radius: 30px!important;
  }
  .title{
    color: white;
    font-weight: 700;
    font-size: 30px;
  }
  .question{
    color: white;
  }

  .fa-envelope{
    color: white;
    
  }
  .hello{
    margin: 10px;
  }
  .bg-white{
    padding-top: 30px;
    border-radius: 13px 13px 0px 0px;
  }
  .name{
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
      }
.contact-us-2{
        width: 100%;
       /* / margin-top: 16px; / */
      background-color: #0046E8;
      color: white;
      border: 1px solid blue;
      padding: 5px;
      font-weight: 500;
      padding: 10px 5px;
      border-radius: 8px;

      }
.c-t{
  color: white;
}
.c-t:hover{
  color: white;
  text-decoration: none;
}  