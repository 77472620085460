.adv-pro-data-gamingNew {
    display: flex;
    margin-left: 34px;
    justify-content: center;
}


.adv-tick-HOME-GAMING {
    width: 38px;
    height: 38px;
    margin-left: 4px;
    margin-top: 12px;
}


.industries-height-Gaming::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #2b2b2b;
    width: 100%;
    /* mix-blend-mode: color; */
    opacity: 56%;
}

.adv-new-text2-GAMING::after {
    content: "";
    border-right: 2px solid #505050;
    height: 21rem;
    margin-right: 29px;
    display: block;
    /* margin-top: -11px; */
    margin-top: 9px;
}

.adv-new-text-GaminG::after {
    content: "";
    border-right: 2px solid #505050;
    height: 21rem;
    margin-right: 32px;
    display: block;
    /* margin-top: -11px; */
    margin-top: 13px;
}

.adv-text-Foremost-Gaming {
    max-width: 64% !important;
    margin-top: 9px;
}

.adv-new-text2-entertainandgaming::after {
    content: "";
    border-right: 2px solid #505050;
    height: 21rem;
    margin-right: 22px;
    display: block;
    /* margin-top: -11px; */
    margin-top: 9px;
}


.adv-pro-data-ai-ml {
    display: flex;
    margin-left: 73px;
    justify-content: center;
}

.adv-text-Foremost-ai-ml {
    max-width: 64% !important;
    margin-top: 9px;
    margin-left: 33px;
}

.adv-new-text-Aiml::after {
    content: "";
    border-right: 2px solid #505050;
    height: 21rem;
    margin-right: 3px;
    display: block;
    /* margin-top: -11px; */
    margin-top: 9px;
}



.adv-new-text-Customer::after {
    content: "";
    border-right: 2px solid #505050;
    height: 21rem;
    margin-right: 35px;
    display: block;
    /* margin-top: -11px; */
    margin-top: 13px;
}

.adv-tick-HOME-Model {
    width: 38px;
    height: 38px;
    margin-left: 26px;
    margin-top: 12px;
}


.adv-pro-data-entertain-bigdata {
    display: flex;
    margin-left: 50px;
    justify-content: center;
}

.adv-pro-data-aws {
    display: flex;
    margin-left: 45px;
    justify-content: center;
}

.adv-text-Foremost-Aws {
    max-width: 56% !important;
    margin-top: 9px;
    margin-left: 33px;
}


.adv-tick-HOME-aws {
    width: 38px;
    height: 38px;
    margin-left: 26px;
    margin-top: 12px;
}